/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
/* @import url('http://fonts.googleapis.com/css?family=Lato:300,400,500,600,700'); */
* {
  /* font-family: 'Poppins', sans-serif, Arial; */
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  /* --font-default: 'Poppins', sans-serif, Arial; */
  --font-default: "Open Sans", Helvetica, Arial, sans-serif;
  /* --primary-color: #9766FF; */
  --primary-color: #4C0E97;
  --secondary-color: #333333;
  --white-color: #fff;
  --black-color: #000;
  --text-gray-light: #4D4D4D;
  --text-blue-color: #23274B;

  --orange-color:#ff6000;
  --orangeLight-color:#ffa000;

  --border-color: #D1E4F2;
  --green-color: #00E910;
  --red-color: #D01C24;

  /* --blue-color: #00A4EF; */
  --blue-color: #0172A6;
  --blue-color2: #3A6BFB;
  --blue-color4:#2350BA;

  --navy-blue-color: #000066;
  --navy-blue-dark: #000033;
  --gray-light: #F0F5FF;
  --cardBg-color: #CACAFF;
  --red-color-light: #dc3545;

  --yellow-color: #FFB100;
  --yellow-color2: #FF9E00;
  --blue-color3: #006AB0;




  --secondary-color-80: rgba(15, 27, 33, 0.80);
  --text-color: #303030;
  --text-color-80: rgba(48, 48, 48, 0.80);
  --text-blue: #3f8cd8;
  --header-bg: #f2f2f2;
  --cardBg-color-80: rgba(237, 242, 245, 0.80);
  --border-blue-light: #7097ab;
  --card-bg: #dde4f8;
  --contact-card-bg: #f0f4f7;
}


html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 16px;
  height: 100%;
  margin: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: var(--white-color);
  font-family: var(--font-default);
  font-size: 16px;
  color: var(--secondary-color);
  line-height: 1.5;
  height: 100%;
  padding: 0;
  margin: 0;
}

img {
  vertical-align: middle;
}

a,
a:focus,
a:active,
a:visited {
  text-decoration: none;
}

a {
  color: var(--light-blue);
  font-size: 14px;
  cursor: pointer;
}

button {
  border-radius: 5px;
  cursor: pointer;
}

button:focus,
input:focus,
textarea:focus,
select:focus,
a:focus {
  border-color: var(--blue-light);
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

input[type="text"],
input[type="email"],
select {
  border: 0px solid;
  border-color: transparent transparent rgb(191, 211, 197) transparent;
  font-size: 16px;
}

p, ul, ol{
  line-height: 1.75em;
}

/*---browser Scrollbar css beginning here */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-moz-scrollbar {
  width: 6px;
  height: 6px;
}

/* *::-webkit-scrollbar-track {
    background: white;
}

*::-moz-scrollbar-track {
    background: white;
} */
*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  /* border: 3px solid white; */
}

*::-moz-scrollbar-thumb {
  background-color: lightgray;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* border: 3px solid white; */
}

/*---browser Scrollbar css end here */


/* Zoom Animation for popup */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}
@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0)
  }
  to {
    -webkit-transform: scale(1)
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}





/**scrollable elements*/
/* .scroll-elem {
  opacity: 0;
  transition: opacity 500ms;
}

.scroll-elem.scroller {
  opacity: 1;
}

.scroller.fade-in {
  animation: fade-in 1.2s ease-in-out both;
}

.scroller.fade-in-bottom {
  animation: fade-in-bottom 1s ease-in-out both;
}

.scroller.slide-left {
  animation: slide-in-left 1s ease-in-out both;
}

.scroller.slide-right {
  animation: slide-in-right 1s ease-in-out both;
} */

/* ------------------------------------- */

/* @keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} */
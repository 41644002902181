.sectionInnerBanner {
    position: relative;
    background-color: var(--white-color);
    padding: 6% 5%;
}

.contactSectionTop {
    /* background: var(--gray-light); */
    background-color: var(--navy-blue-dark);
    width: 100%;
    padding: 4% 5%;
}

.contactSectionTop h1, .contactSectionTop p{
    color: var(--white-color);
}

.commonSection {
    background: var(--white-color);
    width: 100%;
    padding: 6% 5%;
}

.bgLightBlue{
    background: var(--gray-light);
}

.commonSectionInner {
    width: 100%;
    padding: 6% 5%;
  }

  @media (min-width:768.5px){
    .sectionTopPd{
        padding-top: 4%;
    }
  }

@media (max-width:768px) {
    .sectionInnerBanner {
        position: relative;
        background-color: var(--white-color);
        padding: 40px 0px 60px 0px;
    }
    .contactSectionTop {
        padding: 50px 0 60px 0;
    }
    .commonSection{
        padding: 60px 0px;
    }

    .commonSectionInner {
        width: 100%;
        padding: 60px 0px;
      }
}


.gradientBg {
    background: var(--white-color);
    background: -webkit-linear-gradient(var(--white-color), var(--gray-light));
    background: -o-linear-gradient(var(--white-color), var(--gray-light));
    background: linear-gradient(var(--white-color), var(--gray-light));
}

.gradientTopTo {
    background: rgb(149 226 255/0.2);
    background: -webkit-linear-gradient(rgb(149 226 255/0.2), var(--white-color));
    background: -o-linear-gradient(rgb(149 226 255/0.2), var(--white-color));
    background: linear-gradient(rgb(149 226 255/0.2), var(--white-color));
}

.border-b {
    border-bottom: solid 1px rgb(0 0 0/.1);
}

.border {
    border: 0;
    border-bottom: solid 1px rgb(0 0 0/.1);
}



/* --------------------------------------- */

.topInnerBanner {
    display: flex;
}

.topInnerBanner .itemTopContent {
    flex: 1;
    padding-right: 40px;
    display: flex;
    align-items: center;
}

.topInnerBanner .itemTopContent .onlyForMbl {
    display: none;
}

.topInnerBanner .itemTopContent p.xl {
    font-size: 28px;
}

.topInnerBanner .itemTopContent p.large {
    font-size: 24px;
}

.topInnerBanner .itemTopContent p.md {
    font-size: 20px;
}

.topInnerBanner .itemsImage {
    display: flex;
    align-items: center;
}


@media (max-width:992px) {
    .topInnerBanner .itemTopContent {
        flex: unset;
        padding-right: 0px;
    }

    .topInnerBanner .itemTopContent .onlyForMbl {
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .topInnerBanner .itemsImage {
        display: none;

    }

}

@media (max-width:768px) {
    .topInnerBanner .itemTopContent p.xl {
        font-size: 24px;
    }
}


@media (max-width:449.5px) {
    .topInnerBanner .itemTopContent .onlyForMbl {
        padding: 0px 6%;
    }

    .topInnerBanner .itemTopContent .onlyForMbl img {
        width: 100%;
        height: auto;
    }
}


@media (max-width:569.5px) {
    .topInnerBanner .itemTopContent .forLgImg {
        padding: 0px 6%;
    }

    .topInnerBanner .itemTopContent .forLgImg img {
        width: 100%;
        height: auto;
    }
}

/* --------------------------------------- */

.images-wrapper-call-3 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    padding: 0 12%;
}
.images-wrapper-call-3 > div{
    width: 33.33%;
    padding: 10px;    
}


.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}
.images-wrapper > div{
    width: 25%;
    padding: 10px;    
}

.card-images{
    position: relative;
}
.card-images img{
    width: 100%;
    height: auto;
    transition: all 0.35s ease-in-out;
}
.card-images img:hover{
    opacity: .8;
}
.card-images > div{
    border-radius: 0px 0px 24px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 25px 25px 25px;
}
.card-images h3{
    font-size: 28px;
    color: var(--white-color);
    font-weight: 700;
}
.card-images p{
    color: var(--white-color);
    margin-bottom: 0;
}

@media (max-width: 992px){
    .images-wrapper > div{
        width: 33%;
    }
    .card-images > div{
        border-radius: 0px 0px 20px 20px;
        padding: 0px 20px 20px 20px;
    }
    .images-wrapper-call-3 {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) and (min-width: 380.5px) {
    .card-images > div{
        padding: 0px 15px 15px 15px;
    }
    .images-wrapper {
        margin-right: -5px;
        margin-left: -5px;
    }
    .images-wrapper > div{
        width: 50%;
        padding: 5px;
    }
    .card-images h3{
        font-size: 24px;
    }


    .images-wrapper-call-3 {
        margin-right: -5px;
        margin-left: -5px;
    }
    .images-wrapper-call-3 > div{
        width: 50%;
        padding: 5px;    
    }
}
@media (max-width: 380.5px){
    .images-wrapper > div{
        width: 100%;
    }
    .images-wrapper-call-3 > div{
        width: 100%;   
    }
}

/* --------------------------------------- */


.diagram-wp {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
}

.diagram-wp>div {
    margin-bottom: 8px;
}

.diagram-wp img {
    width: 100%;
    height: 100%;
}

.diagram-wp p {
    font-size: 14px;
    color: rgba(15, 27, 33, 0.80);
    text-align: center;
    margin: 0;
}

.diagram-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.diagram-wp .onlyMobile {
    display: none;
}

.orangeBg{
    background-color: var(--orange-color);
    padding: 0;
}

.ourPlayGroudLogos {
    background: var(--white-color);
    padding: 2% 5%;
    border-radius: 15px;
}
@media (max-width: 768px) {
    .diagram-wp .onlyWeb {
        display: none;
    }

    .diagram-wp {
        display: flex;
        justify-content: center;
    }

    .diagram-wp .onlyMobile {
        display: block;
        width: auto;
        height: auto;
    }
}

@media (max-width: 450px) {
    .diagram-wp .onlyMobile {
        width: 100%;
    }
}

/* --------------------------------------- */

.moreAboutLinks ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.moreAboutLinks ul li {
    margin: 0;
    padding: 0;
}

.moreAboutLinks ul li a {
    background: url(../images/icons/arrow-right-orange-icon.svg) no-repeat right center;
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 500;
    text-decoration: underline;
    padding-right: 35px;
    transition: all 0.35s ease-in-out;
    line-height: 2;
}

.moreAboutLinks ul li a:hover {
    text-decoration: none;
    padding-right: 42px;
}

/* --------------------------------------- */

@media (min-width: 767.5px) {
    .oddEvenItems>div:nth-child(even) .cont-inner-flex .item-content {
        padding-right: 0;
        padding-left: 20px;
        order: 2;
    }
    .oddEvenItems>div:nth-child(even) .cont-inner-flex .item-img {
        order: 1;
    }
}

/* --------------------------------------- */

.web-aap-flex {
    padding: 0;
}

.web-aap-flex:not(:last-child) {
    padding-bottom: 4%;
}

.web-aap-flex .cont-inner-flex {
    display: flex;
    border-bottom: solid 1px rgb(0 0 0/.1);
    padding-bottom: 15px;
    margin-bottom: 12px;
}

.web-aap-flex .cont-inner-flex .item-content {
    padding-right: 120px;
}

.web-aap-flex .cont-inner-flex .item-img {
    display: flex;
    align-items: center;
}

.web-aap-flex h3 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 10px;
}

.web-aap-flex h4 {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 400;
    margin-bottom: 5px;
}

.web-aap-flex h5 {
    font-size: 20px;
    color: var(--primary-color);
    margin-bottom: 5px;
}

.web-aap-flex p {
    margin-bottom: 0px;
}

@media (max-width: 768px){
    .web-aap-flex h3 {
        font-size: 26px;
    }    
    .web-aap-flex h4 {
        font-size: 18px;
    }    
    .web-aap-flex h5 {
        font-size: 16px;
    }
}


/* --------------------------------------- */

/* ------for-value page h2 h3 only-------- */
.ourValuesText h2 {
    font-size: 38px;
    color: var(--secondary-color);
    font-weight: 400;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 20px;
}
.ourValuesText h2::after {
    content: "";
    width: 40px;
    height: 4px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

.ourValuesText h3 {
    font-size: 24px;
    color: var(--blue-color3);
    font-weight: 500;
    line-height: inherit;
    margin-bottom: 5px;
}

@media (max-width: 768px){
    .ourValuesText h2 {
        font-size: 30px;
    }  
    .ourValuesText h3 {
        font-size: 20px;
    }  
}

/* -------------- */


.web-aap-flex ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

.web-aap-flex ul li {
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.75em;
    position: relative;
    padding-left: 25px;
}

.web-aap-flex ul li:not(:last-child) {
    padding-right: 30px;
}

.web-aap-flex ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 14px;
    background: var(--orange-color);
    transform: rotate(48deg);
    margin-top: -3px;
}

@media (max-width: 1440px) {
    .web-aap-flex ul {
        flex-wrap: wrap;
    }

    .web-aap-flex ul li:not(:last-child) {
        margin-bottom: 8px;
    }
}

@media (max-width: 992px) {
    .web-aap-flex:not(:last-child) {
        padding-bottom: 50px;
    }

    .web-aap-flex .cont-inner-flex .item-img img {
        width: 300px;
        height: auto;
    }

    .web-aap-flex .cont-inner-flex .item-content {
        padding-right: 20px;
    }
}


@media (max-width: 767.5px) {
    .web-aap-flex .cont-inner-flex .item-img {
        display: none;
    }

    .web-aap-flex .cont-inner-flex .item-content {
        padding-right: 0px;
    }
}


@media (max-width: 576px) {
    .web-aap-flex ul li {
        padding-right: 0 !important;
        width: 100%;
    }
}


@media (min-width: 767.5px) {
    .oddEvenItems>div:nth-child(even) .cont-inner-flex .item-content {
        padding-right: 0;
        padding-left: 20px;
        order: 2;
    }
    .oddEvenItems>div:nth-child(even) .cont-inner-flex .item-img {
        order: 1;
    }
}

/* -------------------------------------------------------- */


.esg-inner-flex {
    display: flex;
    height: 100%;
}

.esg-inner-flex .itemLeft {
    flex: 1;
    padding: 0px 15px 0px 0px;
}

.esg-inner-flex .itemRight {
    flex: 1;
    padding: 0px 0px 0px 15px;
}

.esg-inner-flex .itemImg {
    width: 162px;
    border-radius: 24px;
    background-size: cover;
}

@media (max-width: 992px) {
    .esg-inner-flex .itemImg {
        width: 35%;
    }
}

@media (max-width: 450px) {
    .esg-inner-flex .itemImg {
        display: none;
    }

    .esg-inner-flex .itemLeft {
        padding: 0px 0px 20px 0px;
    }

    .esg-inner-flex .itemRight {
        padding: 0px 0px 0px 0px;
    }
}

.esgBg1 {
    background-image: url(../images/esg-policy-bg-1.webp), url(../images/esg-policy-bg-1.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.esgBg2 {
    background-image: url(../images/esg-policy-bg-2.webp), url(../images/esg-policy-bg-2.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.esgBg3 {
    background-image: url(../images/esg-policy-bg-3.webp), url(../images/esg-policy-bg-3.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.esgBg4 {
    background-image: url(../images/esg-policy-bg-4.webp), url(../images/esg-policy-bg-4.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.esgBg5 {
    background-image: url(../images/esg-policy-bg-5.webp), url(../images/esg-policy-bg-5.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.esgBg6 {
    background-image: url(../images/esg-policy-bg-6.webp), url(../images/esg-policy-bg-6.png);
    background-repeat: no-repeat;
    background-position: center center;
}


/* --------------------------------------- */

.bannerSectionCareer {
    background-image: url(../images/career-banner-img.webp), url(../images/career-banner-img.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 20px 5% 60px 5%;
}

@media (max-width: 768px){
    .bannerSectionCareer {
        background-image: url(../images/career-banner-mbl-img.webp), url(../images/career-banner-mbl-img.png);
        background-position: center right;
        padding: 20px 0px 60px 0px;
    }
  }

.bannerSectionCareer h1{
    color: var(--white-color);
}

.bannerSectionCareer .contentDetails {
    padding: 12% 0 8% 0;
}


  @media (max-width: 480px) {
    .bannerSectionCareer .contentDetails {
        padding: 6% 0 3% 0;
    }
  }

.bannerSectionCareer .contentDetails p{
    font-size: 20px;
    color: var(--white-color);
    font-weight: 600;
}

/* --------------------------------------- */

.card-white {
    background: var(--white-color);
    border: solid 1px var(--border-color);
    border-radius: 5px;
    height: 100%;
    position: relative;
    padding: 20px 20px 70px 20px;
}


.card-white h2,
.card-white h3 {
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 12px;
}

.card-white .img100 img {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
}


.card-white>img {
    margin-bottom: 10px;
}

.card-white p {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 0;
}

.card-white .status{
    margin-bottom: 15px;
}

.card-white .status span {
    background-color: #b0d6f0;
    font-size: 12px;
    color: var(--secondary-color);
    line-height: 1.5;
    border-radius: 4px;
    padding: 4px 10px;
    height: 26px;
    display: inline-block;
}
.card-white .status span.open {
    background-color: var(--green-color);
    color: var(--secondary-color);
}
.card-white .status span.closed {
    background-color: var(--red-color);
    color: var(--white-color);
}
.card-white .status span:not(:last-child) {
    margin-right: 12px;
}

.cardHighlight{
    background-color: var(--card-color);  
    border: solid 1px rgb(0 0 0/.1);  
}
.cardHighlight h2{
    font-size: 28px;
    color: var(--secondary-color-80);
    font-weight: 700;
}

.cardHighlight a.link-md{
    font-size: 16px !important;
}

.card-white .btn-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 20px 20px 20px;
}


.card-white p.date {
    font-size: 14px;
    color: var(--black-color-60);
    font-weight: 600;
    margin-bottom: 10px;
  }


@media (max-width: 768px) {
    .card-white .btn-fixed {
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 576px) {
    .card-white {
        padding: 15px 15px 70px 15px;
    }
}


.card-white .btn-fixed .btn-blue, .btn-rounded {
    background: var(--primary-color) url(../images/icons/arrowRightWhite-icon.svg) no-repeat right center;
    font-size: 14px;
    color: var(--white-color);
    font-weight: 600;
    min-width: 160px;
    height: 38px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 5px 38px 5px 18px;
    border: 0;
}


.card-white .btn-fixed .btn-blue:hover, .btn-rounded:hover,
.card-white .btn-fixed .btn-blue:focus, .btn-rounded:focus{
    background: var(--white-color) url(../images/icons/arrowRightLightSlateBlue-icon.svg) no-repeat right center;
    color: var(--primary-color) !important;
    border: solid 2px var(--primary-color);
}


/* --------------------------------------- */

.table-scroller {
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
}

table.regular {
    /* width: 100%; */
    border-collapse: collapse;
    border: solid 1px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 0;
}

table.regular td {
    border: solid 1px rgba(0, 0, 0, 0.2);
    text-align: left;
    font-size: 16px;
    color: var(--text-color);
    padding: 8px 10px;
}

table.regular td a:hover {
    color: var(--primary-color);
    text-decoration: underline;
}

table.fullWidth {
    width: 100%;
}

table.fullWidth td {
    width: 50%;
}

table.fullWidth {
    width: 100%;
}

table.halfWidth {
    width: 60%;
}

@media (max-width:576px) {
    table.halfWidth {
        width: 100%;
    }
}

.anchor-wp {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.anchor-wp a {
    font-size: 18px;
    color: var(--blue-color3) !important;
    line-height: 32px;
    cursor: pointer;
}

.anchor-wp a:hover {
    color: var(--blue-dark) !important;
    text-decoration: underline !important;
}

.forTarget h2::before {
    content: "";
    display: block;
    height: 98px;
    margin: -98px 0 0;
}


/* --------------------------------------- */

.options-wrapper {
    padding-left: 20px;
}

@media (max-width: 768px) {
    .options-wrapper {
        padding-left: 0px;
    }
}


.options-wrapper .btn-blue {
    width: 100%;
}

.options-wrapper ul.regular li {
    padding-left: 0;
}

.options-wrapper ul.regular li::before {
    top: 50%;
    margin-top: -4px;
  }

.options-wrapper ul.regular li a {
    display: block;
    font-size: 16px;
    color: var(--blue-color3);
    line-height: 1.5;
    padding: 5px 0 5px 25px;
}

.options-wrapper ul.regular li:last-child {
    margin-bottom: 0;
}

.options-wrapper ul.regular li a:hover {
    text-decoration: underline;
}

.options-wrapper .share-icons{
    justify-content: flex-start
}

@media only screen and (max-width: 991.5px) and (min-width: 768.5px) {
    .options-wrapper .share-icons a span {
        display: none;
    }

    .options-wrapper .share-icons a:not(:last-child) {
        margin-right: 30px;
    }
}


/* --------------------------------------- */

.job-details {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.job-details ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.job-details ul li {
    list-style: none;
    font-size: 12px;
    color: var(--secondary-color);
    position: relative;
    padding: 3px 15px;
}

.job-details ul li::after {
    content: "";
    position: absolute;
    background: var(--navy-blue-dark);
    width: 2px;
    height: 24px;
    top: 50%;
    right: 0;
    margin-top: -12px;
}

.job-details ul li:first-child {
    padding-left: 0;
}

.job-details ul li:last-child::after {
    width: 0px;
}

@media (max-width: 768px) {
    .job-details {
        margin-bottom: 20px;
    }

    .job-details ul {
        display: unset;
    }

    .job-details ul li {
        padding: 6px 15px;
        padding-left: 0;
    }

    .job-details ul li::after {
        top: unset;
        bottom: 0px;
        height: 2px;
        width: 100%;
        background: rgba(0, 0, 0, .10);
    }

    .job-details ul li:last-child::after {
        height: 2px;
        width: 100%;
        background: rgba(0, 0, 0, .10);
    }
}

.work-mode {
    margin: 0;
    padding: 0;
}

.work-mode p {
    font-size: 12px;
    color: var(--secondary-color);
    margin-bottom: 0;
}

.work-mode p span {
    background-color: #b0d6f0;
    font-size: 12px;
    color: var(--secondary-color);
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 6px;
    display: inline-block;
}

.work-mode p span.open {
    background-color: var(--green-color);
    color: var(--secondary-color);
}

.work-mode p span.closed {
    background-color: var(--red-color);
    color: var(--white-color);
}

.work-mode p span:not(:last-child) {
    margin-right: 15px;
}

/* --------------------------------------- */

.dashed-wp {
    background: var(--white-color);
    border: dashed 1px var(--secondary-color);
    border-radius: 5px;
    padding: 20px 25px 0px 25px;
    margin-bottom: 20px;
}

.dashed-wp .sub-title {
    background: rgba(112, 151, 171, 0.20);
    padding: 10px 25px 12px 25px;
    margin: 0 -25px 25px -25px;
}

.dashed-wp .sub-title h3 {
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 400;
    margin-bottom: 0;
}

.goToApplyForm::before {
    content: "";
    display: block;
    height: 110px;
    margin: -110px 0 0;
}

.check-flex {
    display: flex;
    padding-top: 5px;
}

.check-flex>div {
    width: 30%;
}


/*custom Radio*/
.customRadio {
    margin: 2px 0;
    padding: 0;
}

.customRadio input[type=radio] {
    opacity: 0;
    height: 0;
}

.customRadio input[type=radio]+label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    padding-left: 30px;
}

.customRadio input[type=radio]+label::before {
    content: "";
    position: absolute;
    left: 2px;
    top: -2px;
    border-radius: 50%;
    border: 2px solid rgba(15, 27, 33, 0.80);
    width: 22px;
    height: 22px;
    background: #fff;
}

.customRadio input[type=radio]+label::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.customRadio input[type=radio]:checked+label::after {
    background: #026fa5;
}

.customRadio input[type=radio]:focus+label::before {
    box-shadow: 0 0px 0px 1px #6F686A, 0 0px 8px #004fc7;
    outline: 2px dotted transparent;
}


/* --------------------------------------- */
/* --------------------------------------- */
/* --------------------------------------- */
/* --------------------------------------- */




/* ----------------------- */
/* ----------------------- */
/* ----------------------- */
/* ----------------------- */
/* ----------------------- */
/* ----------------------- */
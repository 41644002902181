@import url(master.css);
/* @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/Poppins-Light.woff2) format('woff2'),
        url(../fonts/Poppins-Light.woff) format('woff'),
        url(../fonts/Poppins-Light.ttf) format('ttf');
        font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/Poppins-Regular.woff2) format('woff2'),
        url(../fonts/Poppins-Regular.woff) format('woff'),
        url(../fonts/Poppins-Regular.ttf) format('ttf');
        font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/Poppins-SemiBold.woff2) format('woff2'),
        url(../fonts/Poppins-SemiBold.woff) format('woff'),
        url(../fonts/Poppins-SemiBold.ttf) format('ttf');
        font-display: swap;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url(../fonts/Poppins-Bold.woff2) format('woff2'),
        url(../fonts/Poppins-Bold.woff) format('woff'),
        url(../fonts/Poppins-Bold.ttf) format('ttf');
        font-display: swap;
} */

a {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    text-decoration: none;
    font-display: swap;
}
a,
a:active,
a:focus,
a:hover {
    outline: none;
    text-decoration: none;
    color: var(--color-heading);
}

/* *********************************** */

/* ---header css beginning here--- */
header {
    background: var(--white-color);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 15;
    margin: 0;
    padding: 0 5%;
    border-bottom: solid 1px rgb(2 111 165/0.2);
    transition: all 0.35s ease-in-out;
}

header .header-flex {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

header .items-logo {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

header .items-logo a {
    display: block;
}

/* header .items-logo img {
    width: 100%;
    height: 37px;
} */

header .items-logo img.hoverImg {
    display: none;
}

.itemsBtn .menuBtnIcon{
    margin: 0;
    padding: 0;
}

.itemsBtn .menuBtnIcon button {
    width: 42px;
    cursor: pointer;
    padding: 4px;
    background: transparent;
    border: 0 !important;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.itemsBtn .menuBtnIcon button span{
    font-size: 12px;
    color: var(--secondary-color);
    font-weight: 500;
    margin-top: 6px;
}
@media (min-width: 992.5px){
    .itemsBtn .menuBtnIcon{
        display: none;
    }
}

/* ---Transparent header beginning here-- */
.transparentHeader {
    background-color: transparent;
    border-bottom: solid 1px #616161;     
}
.transparentHeader .navigation ul li a {
    color: var(--white-color);
}
.transparentHeader .items-logo img.currentImg {
    display: none;
}
.transparentHeader .items-logo img.hoverImg {
    display: block;
}
.transparentHeader .navigation ul li.dropDown>a::after {
    border-left: 6px solid var(--white-color);
}
.transparentHeader .menuBtnIcon button span{
    color: var(--white-color);
}
.transparentHeader .menuBtnIcon button svg path {
    fill: var(--white-color);
}
@media (min-width: 992.5px){
    .transparentHeader:hover {
        background-color: var(--white-color);
    }
    .transparentHeader:hover .items-logo img.currentImg {
        display: block;
    }
    .transparentHeader:hover .items-logo img.hoverImg {
        display: none;
    }
    .transparentHeader:hover .navigation ul li a {
        color: var(--secondary-color);
    }
    .transparentHeader:hover .navigation ul li.dropDown>a::after {
        border-left: 6px solid var(--secondary-color);
    }
}
/* ---Transparent header end here-- */

.onlyForMobile{
    display: none;
}

@media (max-width: 576px){
    .onlyForWeb{
        display: none;
    }
    .onlyForMobile{
        display: block;
    }

}
.itemsBtn{
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
}


.openModal {
    overflow: hidden;
}
.siteBackdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
}


/* ---mobile navigation beginning here-- */

.mobileMenuList {
    background-color: var(--white-color);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 21;
    transition: all linear 200ms;
    display: flex;
    flex-direction: column;
}

.mobileMenuList .menu-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
}

.mobileMenuList .menu-header a {
    display: flex;
    align-items: center;
}

/* .mobileMenuList .menu-header a img {
    width: 120px;
} */

.mobileMenuList .menu-header button {
    border: 0;
    background: transparent;
    border-radius: 30px;
    width: 46px;
    height: 48px;
    margin-right: -10px;
}

.mobileMenuList .menu-header button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.menu-list {
    flex: 1;
    margin: 0;
    padding: 10px 16px;
    overflow-y: auto;
}

.menu-list ul {
    margin: 0;
    padding: 0;
}

.menu-list ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.menu-list ul li:not(:last-child){
    border-bottom: solid 1px rgb(0 0 0/.1);
}

.menu-list ul li a {
    font-size: 16px;
    color: var(--secondary-color);
    line-height: 1.5;
    display: block;
    margin: 0;
    padding: 20px 0px;
    outline: 0;
}

.menu-list ul li a:hover {
    color: #2b2b2b;
    text-decoration: none;
}

.menu-list ul li ul {
    display: none;
    transition: all 0.3s ease 0s;
    margin: 0;
    padding: 0;
}

.menu-list ul li ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.menu-list ul li ul li a::before {
    content: "";
    width: 8px;
    height: 8px;
    background: url("../images/icons/arrow-forward-icon.svg");
    transition: all 0.5s;
    position: absolute;
    left: 6px;
    top: 50%;
    margin-top: -4px;
}


.menu-list ul li ul li a {
    font-size: 15px;
    color: var(--secondary-color);
    line-height: 1.5;
    display: block;
    margin: 0;
    padding: 10px;
    padding-left: 24px;
    outline: 0;
}

.menu-list ul li.subNav a {
    position: relative;
}

.menu-list ul li.subNav>a::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var( --orange-color);
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -3px;
    transition: all 0.35s ease-in-out;
}

.menu-list ul li a.active {
    position: relative;
    background-color: rgba(0, 0, 0, 0.04);
}

.menu-list ul li a.active::after {
    transform: rotate(180deg);
}

.menu-list ul li a.active~ul {
    display: block;
}




.items-footer {
    padding: 15px 16px;
}

.items-footer .btn-blue {
    width: 100%;
}
.mobileFooterMenu{
    display: flex;
    justify-content: center;
    font-size: 10px;
    color: var(--blue-color3);
    /* padding-bottom: 12px; */
}

.mobileFooterMenu a  {
    font-size: 10px;
    color: var(--blue-color3);
    padding: 0 5px;
}

.mobileFooterMenu p {
    font-size: 10px;
    margin-bottom: 0;
    order: 1;
}

.mobileFooterMenu .itemList{
    display: flex;
    order: 2;
}

@media (max-width: 390px){
    .mobileFooterMenu{
        flex-direction: column;
        align-items: center;
    }
    .mobileFooterMenu p {
        order: 2;
    }
    .mobileFooterMenu p span{
        display: none;
    }
    .mobileFooterMenu .itemList{
        order: 1;
        margin-bottom: 12px;
    }
}

.items-footer .btnWrapper{
    border-bottom: solid 1px rgb(0 0 0/.5);
    padding-bottom: 20px;
    margin-bottom: 15px;
}
.items-footer h3{
    text-align: center;
    font-size: 14px;
}

.items-footer .btn-orange{
    width: 100%;
}

.items-footer .social-wp p {
    font-size: 20px;
  }

/* ---mobile navigation end here-- */

/* ************---old---*************** */

.navigation {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

@media (max-width: 992px) {
    header {
        padding: 0;
    }
}

.backdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.navigation > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex
}

@media (max-width: 992px) {
    .navigation > ul {
        display: none;
    }
}

.navigation ul li {
    display: block;
    position: relative;
}

.navigation ul li>ul {
    display: none;
}

.navigation ul li a {
    display: block;
    text-decoration: none;
    /* white-space: nowrap; */
    color: var(--secondary-color);
    line-height: 1.5;
    font-size: 16px;
    font-weight: 600;
    padding: 28px 24px;
    position: relative;
    border-radius: 0px;
}

.navigation ul li.dropDown a {
    padding-right: 32px;
}

.navigation>ul>li>a:hover {
    color: var(--primary-color);
    /* background: var(--blue-dark); */
}

.navigation ul li a.active {
    border-radius: 0px;
    /* color: var(--orange-color); */
    position: relative;
}

/* .navigation ul li a.active::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--orange-color);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -6px;
    transition: all 0.35s ease-in-out;
} */

.navigation ul li.dropDown>a::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid var(--secondary-color);
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -4px;
    transition: all 0.35s ease-in-out;
}

.navigation ul li.dropDown:hover>a::after {
    border-left: 6px solid var(--orange-color);
    transform: rotate(90deg);
}

.navigation ul li a.active::after {
    border-left: 6px solid var(--orange-color);
}

/* 
.navigation ul li a.active::after {
    color: var(--white-color);
} */

.navigation ul li>ul {
    /* position: absolute;
    min-width: 170px; */
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 900px 0px 900px rgb(0 0 0/0.6);
}


/* //////////////////// */
.navigation ul li:hover>ul {
    display: block;
}

.navigation ul>li>ul {
    border: 1px solid rgba(0, 0, 0, .15);
    border-top: 0;
    background: var(--white-color);
    border-radius: 0px;
    z-index: 10;
    border-top: solid 1px rgb(2 111 165/0.2);
}

.navigation ul li>ul>li {
    width: 100%;
    min-height: 400px;
    padding: 0 5%;
}

/* .navigation ul li li a {
    color: var(--white-color);
    border: 0;
    padding: 10px 15px;
    border-radius: 0;
} */

.navigation li:hover>a {
    color: var(--orange-color) !important;
}

/* .navigation ul li:hover li a:hover {
    background-color: var(--primary-color);
    border: 0;
} */

/* .navigation ul ul ul {
    left: 100%;
    top: 0;
} */


/* ************************ */


.circleBg {
    position: relative;
}

/* .circleBg::after {
    content: "";
    width: 196px;
    height: 98px;
    background: url(../images/menu-bg-circle.svg);
    position: absolute;
    right: 6%;
    bottom: 0;
    opacity: 0.6;
    z-index: -1;
} */


.menu-lg-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 30px 15px 30px 15px;
}
@media (min-width: 1600px) {
    .menu-lg-container {
      max-width: 1420px;
    }
  }

.menu-lg-container ul {
    margin: 0;
    padding: 0;
}

.menu-lg-container ul li a {
    font-weight: 400;
    border: 0;
    padding: 6px 0px !important;
    border-radius: 0;
    position: relative;
}

.menu-lg-container ul.subNav li a {
    font-size: 14px;
    color: var(--text-color);
    font-weight: normal;
}

.menu-lg-container ul li a:hover {
    background-color: transparent;
    color: var(--primary-color);
}

.menu-lg-container ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--primary-color);
    transition: 0.4s;
    position: absolute;
    left: 0;
    bottom: 0;
}

.menu-lg-container ul li a:hover::after {
    width: 50%
}

a.link-md {
    display: unset !important;
    font-size: 20px !important;
    color: var(--blue-color3) !important;
    font-weight: 600 !important;
    padding: 10px 40px 10px 0px !important;
    position: relative;
}

a.link-md::after {
    content: '';
    display: block;
    width: 24px;
    height: 18px;
    background: url("../images/icons/arrow-right-orange-icon.svg");
    transition: 0.4s;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: 8px;
}

a.link-md:hover:after {
    right: 0;
}

.btn-link-flex {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.btn-link-flex button {
    background: var(--card-color);
    border: solid 1px rgba(0 0 0/.1);
    width: auto;
    height: 38px;
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 30px;
    display: flex;
    align-items: center;
    transition: all 0.35s ease-in-out;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.btn-link-flex button:hover {
    background: var(--primary-color);
    color: var(--white-color);
    border: solid 1px var(--primary-color);
}

.btn-link-flex button:not(:last-child) {
    margin-right: 15px;
}

/* --------------------- */
.menuLink a {
    width: 100%;
    display: unset !important;
    padding: 0 !important;
}

.nav-card {
    background: var(--white-color);
    width: 100%;
    height: 200px;
    display: flex;
    align-content: flex-end;
    background-size: cover !important;
    transition: transform .2s;
    position: relative;
}

.nav-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0/.60);
    position: absolute;
    right: 0;
    top: 0;
}

.nav-card>div {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 16px 54px 16px 20px;
}

.nav-card>div::after {
    content: "";
    width: 24px;
    height: 18px;
    background: url(../images/icons/arrow-right-orange-icon.svg);
    position: absolute;
    right: 20px;
    bottom: 16px;
    transition: all 0.35s ease-in-out;
}

.nav-card:hover>div::after {
    right: 12px;
}

.nav-card h2 {
    font-size: 20px;
    color: var(--white-color);
    font-weight: 600;
    position: relative;
    margin-bottom: 12px;
}

.nav-card h2::after {
    content: "";
    width: 30px;
    height: 2px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

.nav-card p {
    font-size: 14px;
    color: var(--white-color);
    margin-bottom: 0;
}

.nav-large-text{
    font-size: 25px !important;
    text-align: center;
}
.nav-large-text span{
    color: var(--orange-color);
}


/*--- Service menu backgrounds ---*/
.servMenuBg1 {
    background-image: url(../images/serv-menu-bg-1.webp), url(../images/serv-menu-bg-1.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg2 {
    background-image: url(../images/serv-menu-bg-2.webp), url(../images/serv-menu-bg-2.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg3 {
    background-image: url(../images/serv-menu-bg-3.webp), url(../images/serv-menu-bg-3.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg4 {
    background-image: url(../images/serv-menu-bg-4.webp), url(../images/serv-menu-bg-4.png);
    background-repeat: no-repeat;
    background-position: center center;
}
.servMenuBg5 {
    background-image: url(../images/serv-menu-bg-5.webp), url(../images/serv-menu-bg-5.png);
    background-repeat: no-repeat;
    background-position: center center;
}


/*--- About menu backgrounds ---*/
.aboutMenuBg1 {
    background-image: url(../images/about-menu-bg-1.webp), url(../images/about-menu-bg-1.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg2 {
    background-image: url(../images/about-menu-bg-2.webp), url(../images/about-menu-bg-2.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg3 {
    background-image: url(../images/about-menu-bg-3.webp), url(../images/about-menu-bg-3.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg4 {
    background-image: url(../images/about-menu-bg-4.webp), url(../images/about-menu-bg-4.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg5 {
    background-image: url(../images/about-menu-bg-5.webp), url(../images/about-menu-bg-5.png);
    background-repeat: no-repeat;
    background-position: center center;
}



/* --------------------- */
/* ******************************* */

.itemsBtn .btnOrange {
    background: #ffa000;
    background: -webkit-linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    background: -o-linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    background: linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    transition: 0.5s;
    background-size: 200% auto;
    padding: 10px 20px;
    height: 100%;
    border: 0;
    border-radius: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    text-transform:uppercase;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }  
  .itemsBtn .btnOrange:hover {
    background-position: right center;
  }

.itemsBtn .btnOrange img{
    margin-left: 10px;
}

@media (max-width: 992.5px){
    .itemsBtn .btnOrange .webIcon{
        display: none;
    }
    .itemsBtn .btnOrange img{
        margin-left: 0px;
    }

      .itemsBtn .btnOrange{
        margin-right: 10px;
      }
}
@media (min-width: 992.5px){
    .itemsBtn .btnOrange .mobileIcon{
        display: none;
    }
}
@media (max-width: 360px){   
    .itemsBtn .btnOrange {
        padding: 10px;     
      }
      .itemsBtn .btnOrange {
        margin-right: 5px;
      }
}
@media (max-width: 1100px) {
    .navigation ul li a {
        padding: 28px 15px;
    }

    .navigation .btn-blue {
        padding: 10px 8px;
        margin-left: 6px;
    }
}
.itemRight ul {
    right: 0px !important;
}

.topMargin {
    margin-top: 80px;
}

@media (max-width: 992px) {
    header .header-flex {
        padding: 0px;
        height: 72px;
    }
    .topMargin {
        margin-top: 72px;
    }
}






/* ************************************* */


.itemRight ul {
    right: 0px !important;
}

.topPadding {
    padding-top: 80px !important;
}


/* @media only screen and (max-width: 1280px) and (min-width: 992px) {
    .header-nav nav ul li {
        padding: 0 10px;
    }   
} */

.container-main {
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
}

@media (min-width: 1600px) {
    .container-main {
        max-width: 1420px;
    }
}

.row-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.row-flex .items-col-1 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.row-flex .items-col-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-3 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-4 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-5 {
    -ms-flex: 0 0 41.66%;
    flex: 0 0 41.66%;
    max-width: 41.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-7 {
    -ms-flex: 0 0 58.33%;
    flex: 0 0 58.33%;
    max-width: 58.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-8 {
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .custom-col-55 {
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
    padding-right: 10px;
    padding-left: 10px;
}

.row-flex .custom-col-45 {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    padding-right: 10px;
    padding-left: 10px;
}



/* column large css beginning here */

.row-flex .items-lg-col-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-lg-col-3 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}


.row-flex .items-lg-col-5 {
    -ms-flex: 0 0 41.66%;
    flex: 0 0 41.66%;
    max-width: 41.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-lg-col-7 {
    -ms-flex: 0 0 58.33%;
    flex: 0 0 58.33%;
    max-width: 58.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}


.row-flex .items-lg-col-8 {
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

@media (max-width:992px) {
    .topPadding {
        padding-top: 72px !important;
    }
    .row-flex .custom-col-55,
    .row-flex .custom-col-45,
    .row-flex .items-lg-col-2,
    .row-flex .items-lg-col-3,
    .row-flex .items-lg-col-5,
    .row-flex .items-lg-col-7,
    .row-flex .items-lg-col-8 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width:768px) {

    .row-flex .items-col-2,
    .row-flex .items-col-3,
    .row-flex .items-col-5,
    .row-flex .items-col-7,
    .row-flex .items-col-8,
    .row-flex .items-col-9 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-flex .items-col-4 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}


@media (max-width:576px) {
    .row-flex .items-col-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* ********************************* */


/* global css beginning here */

h1 {
    font-size: 48px;
    color: var(--secondary-color);
    margin-bottom: 20px;
}
h1 span {
    color: var(--primary-color);    
    font-weight: 400;
}
h2 {
    font-size: 38px;    
    color: var(--secondary-color); 
    margin-bottom: 30px;
}
h1, h2{    
    line-height: 1.2;
    padding-bottom: 8px;    
    position: relative;
}
h1::after, h2::after {
    content: "";
    width: 40px;
    height: 4px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
  }

h3 {
    font-size: 28px;    
    color: var(--secondary-color);
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 15px;
}

@media (max-width: 768px){
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 32px;    
    }
    h3 {
        font-size: 24px;
    }
}

@media (max-width: 576px){
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 28px;    
    }
    h3 {
        font-size: 20px;
    }
}

p {
    font-size: 16px;
    color: var(--black-color);
    font-weight: normal;
    margin-bottom: 15px;
}

p.lg-text {
    font-size: 24px;
    line-height: 1.5;
}

p.md {
    font-size: 18px;
}

p.sm {
    font-size: 14px;
}

p.small {
    font-size: 12px;
}

.bold {
    font-weight: 600;
}

p.textWhite {
    color: var(--white-color);
}

ul.regular {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
}

ul.regular > li {
    list-style: none;
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 400;
    padding: 5px 0px 5px 25px;
    margin-bottom: 6px;
    position: relative;
}
ul.regular > li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 15px;
    background: var(--orange-color);
    transform: rotate(48deg);
}
ul.regular span{
    font-weight: 600;
}

/* ----------- */

ol.regular {
    margin: 0;
    padding: 0;
    padding-left: 25px;
}

ol.regular > li {
    font-size: 16px;
    color: var(--text-color);
    font-weight: 400;
    padding: 0px;
    padding-left: 8px;
    margin-bottom: 8px;
}

/* ----------- */

ul.regular > li ul, ol.regular > li ul {
    list-style: none;
}

ul.regular > li ul li, ol.regular > li ul li {
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

ul.regular > li ul li::before, ol.regular > li ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 10px;
    background: transparent;
    border: solid 1px var(--orange-color);
}



ul.text-white li, .text-white {
    color: var(--white-color);
}


/*----css beginning here---- */

.section-main {
    /* background-color: var(--navy-blue-dark); */
    width: 100%;
    padding: 80px 5% 0px 5%;
    margin: 0px;
    position: relative;
}

.navyBlueBg {
    background-color: var(--navy-blue-dark);
}

.video-wp{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.video-wp video {
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-main .inner-wrapper{
    padding: 10% 0px;
}

.section-main .inner-wrapper h1 {
    font-size: 60px;
    color: var(--white-color);
    width: 850px;
    margin-bottom: 50px;
    padding-bottom: 15px;
}
.section-main .inner-wrapper p {
    font-size: 26px;
    color: var(--white-color);
    font-weight: 500;
    margin-bottom: 50px;
}

@media (max-width:992.5px){

    .section-main .inner-wrapper h1 {
        width:100%;
    }
}

@media (max-width:768px){
    .section-main {
        padding: 80px 0px 0px 0px;
    }

    .section-main .inner-wrapper h1 {
        font-size: 48px;
    }
    .section-main .inner-wrapper{
        padding: 70px 0px;
    }
    .section-main .inner-wrapper p {
        font-size: 24px;
    }    
}

@media (max-width:576px){
    .section-main .inner-wrapper{
        padding: 70px 0px;
    }
    .section-main .inner-wrapper p {
        font-size: 22px;
    }
}

@media (max-width:480px){
    .section-main .inner-wrapper h1 {
        font-size: 40px;
    }    
}







.sectionContent {
    padding: 10% 5%;
    /* border-bottom: solid 1px rgba(0, 0, 0, .10); */
}

.sectionContent .orderOne{
    order: 1;
}
.sectionContent .orderTwo{
    order: 2;
}
.sectionContent p {
    line-height: 2;
    margin-bottom: 20px;
}
.sectionContent p:last-child{
    margin-bottom: 0;
}

.sectionContent .itemVCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50px;
}

.sectionContent .itemRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
}
/* .sectionContent .itemRight h2, .sectionContent .itemRight p{
    text-align: right;
} */

.sectionContent .itemsImg img  {
   width: 100%;
   height: 100%;
}

@media (max-width:992px) {
    .sectionContent .itemsImg{
        margin-bottom: 30px;
    }
    .sectionContent .orderOne{
        order: 2;
    }
    .sectionContent .orderTwo{
        order: 1;
    }
    .sectionContent .itemVCenter {
        padding-right: 10px;
    }
    /* .sectionContent .itemRight h2, .sectionContent .itemRight p{
        text-align: left;
    } */
    .sectionContent .itemRight {
        padding-left: 10px;
    }
}

@media (max-width:768px){
    .sectionContent {
        padding: 70px 0px;
    }
}













/* ---buttons css beginning here--- */


button.btn-rounded-blue {
    background: var(--blue-color);
    font-size: 22px;
    color: var(--white-color);
    font-weight: 600;
    border-radius: 30px;
    border: 0;
    padding: 10px 30px;
    height: 60px;
}

button.btn-rounded-blue:hover {
    background: var(--white-color) !important;
    color: var(--blue-color) !important;
}

button.btn-rounded-navyBlue {
    background: var(--navy-blue-dark);
    font-size: 22px;
    color: var(--white-color);
    font-weight: 600;
    border-radius: 30px;
    border: 0;
    padding: 10px 30px;
    height: 60px;
}

button.btn-rounded-navyBlue:hover {
    background: var(--white-color);
    color: var(--navy-blue-dark);
}

button.btn-rounded-white {
    background: var(--white-color);
    font-size: 22px;
    color: var(--navy-blue-dark);
    font-weight: 600;
    border-radius: 30px;
    border: solid 2px var(--white-color);
    padding: 10px 30px;
    height: 60px;
}
button.btn-rounded-white:hover {
    background: var(--navy-blue-dark) !important;
    color: var(--white-color) !important;
}

button.btn-rounded-white2 {
    background: var(--white-color);
    font-size: 22px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 30px;
    border: solid 2px var(--white-color);
    padding: 10px 30px;
    height: 60px;
}
button.btn-rounded-white2:hover {
    background: var(--primary-color) !important;
    color: var(--white-color) !important;
}

.btn-outLine-navyBlue {
    border: solid 2px var(--navy-blue-dark);
}

.btn-outLine-navyBlue:hover {
    color: var(--navy-blue-dark) !important;
    border: solid 2px var(--navy-blue-dark);
}


/* ----------------------- */

button.btn-primary {
    background: var(--primary-color);
    border: solid 2px var(--primary-color);
    font-size: 22px;
    color: var(--white-color);
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 30px;
    height: 60px;
    /* transition: 0.5s; */
}
button.btn-primary:hover {
    background: var(--white-color);
    color: var(--primary-color);
}

.btn-outLine-primary {
    border: solid 2px var(--primary-color);
}

.btn-outLine-primary:hover {
    border: solid 2px var(--primary-color);
}






/* ------- */

button.btn-secondary {
    background: var(--primary-color);
    font-size: 16px;
    color: var(--white-color);
    font-weight: 600;
    border-radius: 30px;
    border: 0;
    min-width: 110px;
    height: 48px;
    padding: 10px 25px;
    border: solid 2px var(--primary-color);
    /* transition: 0.5s; */
}

button.btn-secondary:hover {
    background: var(--white-color);
    color: var(--primary-color);
}

/* ------- */

button.btn-outline-rounded {
    background: transparent;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 30px;
    border: 0;
    min-width: 110px;
    height: 48px;
    padding: 10px 25px;
    border: solid 2px var(--primary-color);
}

button.btn-outline-rounded:hover {
    background: var(--primary-color);
    color: var(--white-color);
}



.btn-orange {
    background: var(--orangeLight-color);    
    background-image: -webkit-linear-gradient(to left, var(--orangeLight-color), var(--orange-color), var(--orangeLight-color), var(--orangeLight-color));
    background-image: -o-linear-gradient(to left, var(--orangeLight-color), var(--orange-color), var(--orangeLight-color), var(--orangeLight-color));
    background-image: linear-gradient(to left, var(--orangeLight-color), var(--orange-color), var(--orangeLight-color), var(--orangeLight-color));
    background-position: 50% 0;
    background-size: 300% 100%;
    transition: 0.6s;
    border: 0;
    border-radius: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    height: 48px;
    text-transform:uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 8px 11px 20px rgba(0, 0, 0, 0.25);
    padding: 10px 30px;
  }  
  .btn-orange:hover {
    background-position: 100% 0px;
  }
  .btn-orange img{
    margin-left: 10px;
  }

@media (max-width:576px) {
    button.btn-rounded-navyBlue{
        width: 100%;
    }
    button.btn-primary {
        width: 100% !important;
    }
}


/* ------- */


button.btn-yellow {
    background: var(--yellow-color2) url(../images/icons/arrow-right_icon.svg) no-repeat right center;
    font-size: 16px;
    color: var(--black-color);
    font-weight: 600;
    border-radius: 30px;
    border: 0;
    min-width: 110px;
    height: 42px;
    padding: 10px 32px 10px 25px;
    border: solid 2px var(--yellow-color2);
    /* transition: 0.5s; */
}

button.btn-yellow:hover {
    background: var(--white-color) url(../images/icons/arrow-right_icon.svg) no-repeat right center;
    color: var(--black-color);
}

.btn-100 {
    width: 100%;
}


@media (max-width:576px){
    button{
        width: 100%;
    }
}




.sectionPrimaryCard {
    padding: 5% 0px;
    margin: 0;
    /* border-bottom: solid 1px rgba(0, 0, 0, .10); */
}

.sectionPrimaryCard .card-inner {    
    padding: 100px 5%;
    background-color: var(--gray-light);
}

.sectionPrimaryCard .flexVcenter {
    display: flex;
    align-items: center;
    position: relative;
}

.sectionPrimaryCard .titleFixed {
    position: absolute;
    left: 12px;
    top: -60px;
}

.sectionPrimaryCard .leftSpace {
    padding-left: 120px;
}

.sectionPrimaryCard .orderOne {
    order: 1;
}

.sectionPrimaryCard .orderTwo {
    order: 2;
}

.sectionPrimaryCard .cardFlex {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.sectionPrimaryCard .row-flex .items-col-2,
.sectionPrimaryCard .row-flex .items-col-3 {
    margin-bottom: 0 !important;
}


.card-primary {
    background-color: var(--white-color);
    border-radius: 22px;
    position: relative;
    padding: 50px 40px 70px 40px;
    text-align: center;
    position: relative;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.card-primary h3 {
    font-size: 26px;
    color: var(--secondary-color);
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

@media (max-width:992px){
    .card-primary h3 {
        font-size: 22px;
    }
}

.card-primary p {
    color: var(--black-color);
    text-align: center;
    margin-bottom: 0;
}

.cardBlue{
    background-color: var(--navy-blue-dark);
}

.cardBlue h3 {
    color: var(--white-color);
}

.cardBlue p {
    color: var(--white-color);
}

.blueLight {
    background-color: var(--cardBg-color);
}

.bluebg {
    background-color: var(--blue-color);
}

.bluebg h3,
.bluebg p {
    color: var(--white-color);
}

.sectionPrimaryCard .cardMarginTop {
    margin-top: -160px;
}

.sectionPrimaryCard .cardMarginBtm {
    margin-bottom: -215px;
}
.card-primary .readMore {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 20px 20px;
    border-radius: 0 0 15px 15px;
}

.card-primary .readMore a {
    font-size: 16px;
    color: var(--secondary-color); 
    font-weight: 500;
    position: relative;
    padding: 10px 25px 10px 15px;
    border-radius: 30px;
}

.card-primary .readMore a:hover {
    background: rgba(0, 0, 0, 0.08)
}

.card-primary .readMore a::after {
    content: "";
    width: 7px;
    height: 12px;
    background: url("../images/icons/arrow-right-icon-2.svg");
    transition: all 0.5s;
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -6px;
}

.card-primary .readMore a.whiteIcon::after {
    background: url("../images/icons/arrow-right-white-icon.svg");
}

.card-primary .readMore a:hover::after {
    right: 8px;
    color: var(--primary-color);
}

.bluebg .readMore a,
.bluebg .readMore a::after,
.bluebg .readMore a:hover::after {
    color: var(--white-color);
}
.cardBlue .readMore a,
.cardBlue .readMore a::after,
.cardBlue .readMore a:hover::after {
    color: var(--white-color);
}


@media (max-width:1366px) {
    .sectionPrimaryCard .leftSpace {
        padding-left: 70px;
    }
    .sectionPrimaryCard .card-primary {
        padding: 90px 40px;
    }

    .sectionPrimaryCard .card-next-level {
        padding: 120px 40px;
    }
}

@media (max-width:1199.5px) {
    .sectionPrimaryCard .leftSpace {
        padding-left: 30px;
    }
    .sectionPrimaryCard .card-primary {
        padding: 60px 30px 70px 30px;
    }
    .sectionPrimaryCard .card-next-level {
        padding: 120px 40px;
    }
}

@media (max-width:992px) {
    .sectionPrimaryCard .leftSpace {
        padding-left: 0px;
    }
    .sectionPrimaryCard .card-primary {
        padding: 40px 30px 70px 30px;
    }
    .sectionPrimaryCard .card-next-level {
        padding: 50px 15px;
    }
}

@media only screen and (max-width:991.5px) and (min-width:768.5px) {
    .sectionPrimaryCard .card-primary {
        padding: 40px 20px 70px 20px;
    }

}


@media (max-width:768px) {
    .sectionPrimaryCard .card-inner {    
        padding: 70px 0;
        background-color: var(--gray-light);
    }
    .sectionPrimaryCard {
        padding: 0px;
        margin: 0;
    }
    .card-primary {
        min-height: 230px;
        margin-bottom: 20px;
      }    
    .sectionPrimaryCard .cardMarginTop {
        margin-top: 0px;
    }
    .sectionPrimaryCard .orderOne {
        order: 2;
    }
    .sectionPrimaryCard .orderTwo {
        order: 1;
    }
    .sectionPrimaryCard .cardMarginBtm {
        margin-bottom: 0px;
    }
    .sectionPrimaryCard .flexVcenter {
        display: unset;
    }
    .sectionPrimaryCard .titleFixed {
        position: unset;
    }
    .sectionPrimaryCard .titleFixed h2 {
        margin-bottom: 30px;
    }
    .sectionPrimaryCard .cardFlex{
        padding-top: 0px;
    }
    .sectionPrimaryCard .cardFlex .blueLight{
        margin-bottom: 20px;
    }
}

@media (max-width: 360px){
    .sectionPrimaryCard .card-primary {
        padding: 40px 15px 65px 15px;
    }
}






.sectionOurProducts {
    background-color: var(--gray-light);
    padding: 10% 5%;
    margin: 0;
}

@media (max-width: 768px) {
    .sectionOurProducts {
        padding: 70px 0px;
        margin: 0;
    }
  }

.products-card{
  border: 0;
  border-radius: 12px;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.products-card .img-wp{
    border-radius: 12px 12px 0px 0px;
}
.products-card .img-wp img{
    width: 100%;
    height: auto;
    border-radius: 12px 12px 0px 0px;
}

.products-card .content-wp{
    flex: 1;
    background-color: var(--navy-blue-dark);
    border-radius: 0px 0px 12px 12px;
    padding: 40px 30px 25px 30px;
}

.products-card .content-wp img{
    margin-bottom: 30px;
}

.products-card .content-wp h3{
    font-size: 20px;
    color: var(--white-color);
}
.products-card .content-wp p{
    font-size: 16px;
    color: rgba(255 255 255/.8);
    margin-bottom: 25px;
}

.products-card .content-wp button{
    display: flex;
    align-items: center;
    text-align: left;
    width: 150px;
    padding-left: 20px;
}


.sectionWork {
    padding: 10% 5%;
    margin: 0;
}

.sectionWork .row-flex > div:first-child,
.sectionWork .row-flex > div:nth-child(2),
.sectionWork .row-flex > div:nth-child(3){
    margin-bottom: 60px;
}

.sectionWork .our-story-card {
    height: 100%;
    text-align: center;
    padding: 0px 30px;
}

.sectionWork .our-story-card h3, .sectionWork .our-story-card img {
    margin-bottom: 20px;
}

.sectionWork .our-story-card p {
    line-height: 2;
    margin-bottom: 0;
}

@media (max-width: 992px){
    .sectionWork .our-story-card {
        padding: 0px 10px;
    }
}
@media (max-width: 768px) {
    .sectionWork {
        padding: 70px 0px;
        margin: 0;
    }
    .sectionWork .row-flex>div:not(:last-child) {
        margin-bottom: 60px;
    }
    .sectionWork .our-story-card {
        text-align: center;
        padding: 0px;
    }
}
.sectionBlueBg{
    padding: 10% 5%;
    margin: 0;
    background-color: var(--navy-blue-dark);
}

.sectionBlueBg .textWhite{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sectionBlueBg .textWhite h2 {
    color: var(--white-color);
    padding-right: 100px;
    margin-bottom: 0;
}
.sectionBlueBg .card-primary p{
    line-height: 2;
}

@media (max-width:1100px){
    .sectionBlueBg .card-primary{
        padding: 60px 15px;
    }
}

@media (max-width:992px){
    .sectionBlueBg .textWhite h2 {
        padding-right: 0px;
    }
}


@media only screen and (max-width: 992px) and (min-width: 768.5px){
    .sectionBlueBg .items-col-3{
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }
    .sectionBlueBg .items-col-8{
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
    }
}

@media (max-width:768px){
    .sectionBlueBg{
        padding: 70px 0px;
    }       
    .sectionBlueBg .textWhite h2 {
        color: var(--white-color);
        margin-bottom: 30px;
    }
}


.sectionLogos {
    background-color: var(--white-color);
    padding: 10% 5%;
}

@media (max-width:768px){
    .sectionLogos {
        background-color: var(--white-color);
        padding: 70px 0px;
    }       
}

.sectionContact {
    background-color: var(--gray-light);
    padding: 5%;
}

.sectionContact .btn-primary {
    width: 276px;
}

.sectionContact .btn-rounded-blue {
    width: 276px;
    border: solid 2px var(--blue-color);
}

.sectionContact .btn-rounded-blue:hover {
    background: var(--white-color) !important;
    border: solid 2px var(--blue-color);
}

@media (max-width:768px) {
    .sectionContact {
        background-color: var(--gray-light);
        padding: 70px 0px;
    }
}

@media (max-width:576px) {

    .sectionContact .btn-primary,
    .sectionContact .btn-rounded-blue {
        width: 100%;
    }
}






/* ----------------------------- */

.floating-label {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.floating-label input {
    width: 100%;
    background: var(--white-color);
    height: 54px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    border: solid 1px rgba(0, 0, 0, 0.2);
    padding: 10px 12px 14px 12px !important;
    border-radius: 12px;
}

.floating-label input:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* .floating-label select {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    height: 48px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    border: 0 !important;
    padding: 11px 12px !important;
    border-radius: 5px;
}

.floating-label select:focus {
    outline: none;
} */

.floating-label textarea {
    width: 100%;
    background: var(--white-color);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    height: 150px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    padding: 10px 12px !important;
    border-radius: 12px;
}

.floating-label textarea:focus {
    outline: none;
}

.floating-label label {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.floating-label input::placeholder,
.floating-label textarea::placeholder{
    opacity:0;
}
.floating-label:focus-within > label,
.floating-label input:not(:placeholder-shown) + label,
.floating-label textarea:not(:placeholder-shown) + label{
    top: -18px;
    left: 5px;
    font-size: 12px;
    font-weight: 500;
    color: var(--navy-blue-dark);
}

/* .floating-label select:focus~label,
.floating-label select:valid~label,
.floating-label textarea:focus~label,
.floating-label textarea:valid~label,
.floating-label input:focus~label,
.floating-label input:valid~label {
    top: -18px;
    left: 5px;
    font-size: 12px;
    font-weight: 500;
    color: var(--navy-blue-dark);
} */


.floating-label .error-msg {
    font-size: 12px;
    color: var(--red-color-light);
    position: absolute;
    bottom: -16px;
    left: 5px;
    /* display: none; */
}













.logos-wrapper {
    margin: 0;
    padding: 0;
}

.logos-wrapper ul {
    margin: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: center;
    flex-wrap: wrap;
}

.logos-wrapper ul li {
    list-style: none;
    text-align: center;
    width: 25%;
    padding: 40px 15px;
}

.logos-wrapper ul li img {
    vertical-align: middle;
}

.logos-wrapper ul li img:hover {
    opacity: .6;
}

@media (max-width:1280px) {
    .logos-wrapper ul li img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width:992px) {

    .logos-wrapper ul li {
        width: 25%;
        padding: 20px 25px;
    }

    .logos-wrapper {
        padding: 0 0 3% 0;
    }
}

@media (max-width:768px) {
    .section-logos {
        padding: 80px 12px 60px 12px;
    }

    .logos-wrapper ul li {
        width: 33%;
    }
}

@media (max-width:480px) {
    .logos-wrapper ul {
        margin-left: -15px;
        margin-right: -15px;
    }

    .logos-wrapper ul li {
        width: 50%;
    }

    .logos-wrapper ul li {
        padding: 15px;
    }
}


.items-center {
    display: flex;
    justify-content: center;
}

.items-end {
    display: flex;
    justify-content: flex-end;
}


.social-wp{
    padding:0;
    margin-bottom: 20px;
}
.social-wp p{
    font-size: 22px;
    color: var(--secondary-color-80);
    font-weight: 600;
    margin-bottom: 15px;
}
.social-wp .social-icons {
    width: 230px;
    display: flex;
    justify-content: space-between;
}
.social-wp .social-icons a {
    width: 38px;
    height: 38px;
    background: var(--blue-grey-color);
    background: #80909E;
    border-radius: 30px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-wp .social-icons a img {
    width: 100%;
    height: 100%;
}
.social-wp .social-icons a:hover {
    background: var(--primary-color);
}



/* ---Footer Css beginning here--- */

footer {
    width: 100%;
    margin: 0;
    padding: 0px 5%;
    position: relative;
}

.footerBg{
    width: 26%;
    height: 56%;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 20px 0 0 0;
    background-image: url(../images/building_signitiveAi.webp), url(../images/building_signitiveAi.png);
    background-repeat: no-repeat;
    background-position: center top;
    opacity: .5;
}

@media (max-width: 992px){
    .footerBg{
        width: 35%;
        height: 25%;
    }
}

@media (max-width: 768px){
    .footerBg{
        display: none;
    }
}

@media (min-width:992.5px) {
    footer .container-main {
        display: flex;
        justify-content: flex-start;
    }
}

footer .footerLeftContent {
    width: 350px;
    background-color: #F9FBFD;
    padding: 60px 35px 60px 0;
    position: relative;
}

footer .footerLeftContent::before {
    content: "";
    width: 600%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -560%;
    bottom: 0;
    background-color: #F9FBFD;
    z-index: -1;
}

footer .footerLeftContent::after {
    content: "";
    width: 32px;
    height: 80%;
    position: absolute;
    top: 10%;
    right: 0;
    border-radius: 8px;
    border-right: solid 1px var(--primary-color);
}

footer .footerLeftContent h2 {
    font-size: 20px;
    color: var(--blue-dark);
    margin-bottom: 20px;
}
footer .footerLeftContent .btn-orange {
    width: 100%;
}

footer .footerLeftContent .action-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

footer .footerLeftContent .action-wrapper a:first-child {
    font-size: 16px;
    color: var(--blue-color3);
    line-height: 1.5;
    display: flex;
    align-items: center;
}

footer .footerLeftContent .action-wrapper a:first-child:hover {
    text-decoration: underline;
}

footer .footerLeftContent .action-wrapper a:first-child img {
    margin-right: 6px;
}

footer .footerLeftContent .action-wrapper a:last-child {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer .footerLeftContent .action-wrapper a:last-child:hover {
    background-color: rgb(2 111 165/.1);
}

/* ************************ */

footer .footerRightContent {
    flex: 1;
    padding: 60px 0 60px 35px;
}

footer .footerRightContent h2 {
    font-size: 16px;
    color: var(--blue-dark);
    line-height: 1;
    margin-bottom: 10px;
    padding-bottom: 0;
}

footer h2::after{
    position: unset;    
}

footer .footerRightContent ul {
    margin: 0;
    padding: 0;
}

footer .footerRightContent ul li {
    list-style: none;
    padding: 7px 0;
}

footer .footerRightContent ul li a {
    font-size: 14px;
    color: var(--secondary-color);
    line-height: 1.5;
    display: block;
    padding: 2px 0;
    position: relative;
}

footer .footerRightContent ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--orange-color);
    transition: 0.4s;
    position: absolute;
    left: 0;
    bottom: 0;
}

footer .footerRightContent ul li a:hover::after {
    width: 50%
}

footer .footerRightContent .row-flex>div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 60px;
}

footer .row-flex .items-col-4 {
    margin-bottom: 0;
}

@media only screen and (max-width:768px) and (min-width:360px) {
    footer .row-flex .items-col-4:first-child,
    footer .row-flex .items-col-4:nth-child(2) {
        margin-bottom: 25px;
    }
}

@media (max-width:768px) {
    .footerRightContent .row-flex .items-col-4 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width:359.5px) {
    .footerRightContent .row-flex .items-col-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px;
    }

    footer .footerRightContent button.btn-blue {
        width: 100%;
        margin-top: 20px;

    }

    footer .footerRightContent .row-flex>div:last-child {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width:1199.5px) and (min-width:992.5px) {
    footer .footerLeftContent {
        padding: 60px 20px 60px 0;
    }

    footer .footerRightContent {
        padding: 60px 0 60px 20px;
    }

    footer ul li {
        padding: 5px 0;
    }
}

@media (max-width:992px) {
    footer .footerLeftContent {
        width: auto;
        padding: 60px 15px;
        margin-left: -15px;
        margin-right: -15px;
    }

    footer .footerLeftContent .onlyForTab {
        width: 350px;
        margin: 0 auto;
    }

    footer .footerRightContent {
        flex: unset;
        width: 100%;
        padding: 60px 0 42px 0;
    }

    footer .footerLeftContent::after {
        left: -400%;
    }

    footer .footerLeftContent::before {
        position: unset;
    }
}

@media (max-width:992px) {
    footer {
        padding: 0px;
    }
}

@media (max-width:576px) {
    footer .footerLeftContent .onlyForTab {
        width: 100%;
        margin: 0 auto;
    }

    footer .footerLeftContent .share-icons a {
        margin-left: 0px;
    }
}

/* --Footer bottom Nav css beginning here--- */

.footerBottomSection {
    border-top: solid 1px #9CB6CC;
    padding: 16px 5%;
}

.footerBottomSection .copyRightText {
    display: flex;
    align-items: center;
    order: 1;
}

.footerBottomSection .bottomNav {
    order: 2;
}

.footerBottomSection .copyRightText p {
    font-size: 12px;
    color: var(--text-color);
    line-height: 1.5;
    margin: 0;
}

.footerBottomSection .items-col-2 {
    margin-bottom: 0;
}

.footerBottomSection ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
}

.footerBottomSection ul li {
    list-style: none;
    padding: 0px;
}

.footerBottomSection ul li:not(:last-child) {
    margin-right: 20px;
}

.footerBottomSection ul li a {
    font-size: 12px;
    color: var(--text-color);
    line-height: 1.5;
    display: block;
    text-decoration: underline;
    padding: 0;
}

.footerBottomSection ul li a:hover {
    text-decoration: none;
}

@media (max-width:768px) {
    .footerBottomSection {
        border-top: solid 1px #9CB6CC;
        padding: 16px 0px;
    }

    .footerBottomSection .copyRightText {
        justify-content: center;
        order: 2;
        margin-bottom: 5px;
    }

    .footerBottomSection .bottomNav {
        order: 1;
        margin-bottom: 15px;
    }

    .footerBottomSection ul {
        justify-content: center;
    }

    .footerBottomSection ul li:not(:last-child) {
        margin-right: 18px;
    }
}

@media (max-width:380px) {
    .footerBottomSection ul {
        flex-direction: column;
        align-items: center;
    }

    .footerBottomSection ul li:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 12px;
    }

    .footerBottomSection .bottomNav {
        margin-bottom: 20px;
    }
}



/* *************************** */

#gototop-btn {
    display: none;
    position: fixed;
    bottom: 12px;
    right: 20px;
    border: none;
    outline: none;
    width: 75px;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    z-index: 15;
}
#gototop-btn img {
    margin-bottom: 2px;
}
#gototop-btn:hover img {
    opacity: 0.8;
}
#gototop-btn span {
    display: block;
    text-align: center;
    font-size: 12px;
    color: var(--navy-blue-color);
}

/*----As css beginning here---- */

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 20;
}

.overlayTwo {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0/0.6);
    z-index: 21;
  }

.cookie-wrapper {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding: 30px 5%;
    z-index: 20;
}

.cookie-wrapper p.md {
    line-height: 1.4;
}

.cookie-wrapper .itemsContent p {
    font-size: 14px;
    color: var(--text-color);
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
}

.cookie-wrapper p:last-child {
    margin-bottom: 5px;
}

.cookie-wrapper a {
    font-size: 14px;
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 600;
}

.cookie-wrapper a:hover {
    text-decoration: none;
}

.cookie-wrapper .cookies-flex {
    display: flex;
    justify-content: space-between;
}

.cookie-wrapper .itemsContent {
    flex: 1;
    padding-right: 30px;
}

.cookie-wrapper .itemsBtn {
    display: flex;
    justify-content: space-between;
}

.cookie-wrapper .itemsBtn button {
    font-size: 14px;
    width: auto;
    padding: 10px 15px;
}

.cookie-wrapper .itemsBtn .btn-outline-rounded {
    margin-right: 15px;
}

@media (max-width: 768px) {
    .cookie-wrapper {
        padding: 20px 0px 25px 0px;
    }

    .cookie-wrapper .itemsBtn button {
        width: 100%;
    }

    .cookie-wrapper .cookies-flex {
        flex-direction: column;
    }

    .cookie-wrapper .itemsContent {
        flex: unset;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .cookie-wrapper .itemsBtn {
        display: unset;
    }

    .cookie-wrapper .itemsBtn .btn-outline-rounded {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

/* --------------------------- */


/*custom checkbox*/
.customcheckeBox {
    margin: 2px 0;
    padding: 0;
    margin-bottom: 5px;
}

.customcheckeBox input[type=checkbox] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    /*&:checked + label::before {
        background: #5ac5c9;
    }*/
}

.customcheckeBox input[type=checkbox]+label {
    display: block;
    position: relative;
    padding: 0;
    font-size: 18px;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.5;
}

.customcheckeBox input[type=checkbox]+label:before {
    content: "";
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    background: var(--white-color);
    border: solid 2px var(--secondary-color);
    border-radius: 5px;
}

.customcheckeBox input[type=checkbox]:checked+label::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 5px;
    border-left: 2px solid var(--navy-blue-dark);
    border-bottom: 2px solid var(--navy-blue-dark);
    height: 6px;
    width: 12px;
    transform: rotate(-45deg);
}

.customcheckeBox input[type=checkbox]:focus+label::before {
    outline: solid 2px #5d9dd5;
    box-shadow: 0 0px 8px #5e9ed6;
}

/* ***************** */

.settingsModal,
.cookiespolicyModal,
.popupModal {
    display: none;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
    z-index: 15;
    width: 500px;
    min-height: 190px;
    max-height: 95%;
    border-radius: 5px;
    padding: 0px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    transition: all ease-in 200ms;
    z-index: 25;
}
.settingsModal,
.cookiespolicyModal {
    width: 570px;
    height: 95%;
}

@media (max-width: 768px) {
    .settingsModal,
    .cookiespolicyModal,
    .popupModal {
        width: 540px;
    }
}

@media (max-width: 576px) {
    .settingsModal,
    .cookiespolicyModal,
    .popupModal {
        width: 90%;
    }
}

.modal-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.modal-flex .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.modal-flex .modalTitle h2 {
    font-size: 24px;
    margin-bottom: 0px;
}

.modal-flex .modalBody {
    margin: 0;
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 80%;
}
.modal-flex .modalBody h3 {
    font-size: 22px;
}

.modal-flex .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.modal-flex .modalTitle a {
    margin-top: -3px;
    margin-right: -2px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-flex .modalTitle a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
}
.modal-flex .modalTitle a:focus {
    border-radius: 30px;
}

/* *************************** */

a.hyperlink {
    color: var(--blue-color3);
    text-decoration: none;
}
a.hyperlink:hover {
    text-decoration: underline;
}



/* ----------------------- */


.form-group {
    margin: 0;
    padding: 0;
    position: relative;
}

.form-group label {
    display: inline-block;
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 5px;
}
.form-group label span {
    color: var(--red-color);
}
.form-group input,
.form-group select,
.form-group textarea {
    display: block;
    width: 100%;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 400;
    line-height: 1.5;
    background: var(--white-color);
    border: solid 1px var(--border-blue-light);
    border-radius: 5px;
    padding: 8px 12px;
}

.form-group input,
.form-group select {
    height: 48px;
}

.form-group select.dropIcon {
    background: #fff url(../images/icons/arrowDown.svg) no-repeat right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 38px;
}


.form-group input::placeholder,
.form-group textarea::placeholder {
    color: rgba(15, 27, 33, 0.40);
}

.form-group .error-msg {
    font-size: 13px;
    color: #dc3545;
    position: absolute;
    bottom: -18px;
    left: 0;
    /* display: none; */
}

.form-group .btn-blue {
    width: 100%;
}


/* ----------------------- */

.contact-card .form-group {
    margin-bottom: 15px;
}

.contactImgBg {
    width: 100%;
    height: 100%;
    background-image: url(../images/contact-form-img.webp), url(../images/contact-form-img.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 24px;
}

.ourOffices .card-white{
    padding: 30px;
    border-radius: 20px;   
}
.ourOffices .card-white h3{
    padding-bottom: 10px;
    position: relative; 
}
.ourOffices .card-white h3::after {
    content: "";
    width: 50%;
    height: 2px;
    background: var(--orange-color);
    position: absolute;
    left: 0;
    bottom: 0px;
  }


/* ----------------------- */

.section-merfTop {
    background-color: var(--white-color);
    margin-bottom: 80px;
}
@media (max-width: 576px){
    .section-merfTop {
        margin-bottom: 0px;
    }
}

.section-merfTop .container-main{
    position: relative;
}

.innerBanner-wp {
    background-image: url(../images/merf-banner-bg.webp), url(../images/merf-banner-bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 120px 5% 0px 5%;
}

.innerBanner-wp .img-wp {
    display: flex;
    justify-content: flex-end;
}

.innerBanner-wp .img-wp img {
    margin-bottom: -40px;
}

.innerBanner-wp .container {
    position: relative;
}

.innerBanner-wp .banner-content {
    padding-top: 30px;
}

.innerBanner-wp .banner-content h1 {
    color: var(--white-color);
    text-shadow: 1px 3px 5px #000;
}

.innerBanner-wp .banner-content p{
    font-size: 24px;
    color: var(--white-color);
    text-shadow: 1px 3px 5px #000;
    width: 60%;
}

.innerBanner-wp .banner-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 50px;
    display: flex;
}

.innerBanner-wp .banner-content ul li {
    font-size: 26px;
    color: var(--white-color);
    font-weight: 500;
    line-height: 1;
    border-right: solid 4px var(--white-color);
    padding: 0 8px;
}

.innerBanner-wp .banner-content ul li:first-child {
    border-left: solid 4px var(--white-color);
}

.innerBanner-wp .banner-content .btn-rounded-navyBlue{
    border: solid 2px var(--white-color);
}

.innerBanner-wp .position-carIcon {
    position: absolute;
    right: 185px;
    bottom: -75px;
}

.innerBanner-wp .btnFlex {
    display: flex;
}

.innerBanner-wp button:not(:first-child) {
    margin-left: 20px;
}

@media (max-width: 992px) {
    .innerBanner-wp .banner-content p{
        width: 100%;
    }
    .section-merfTop .row-flex .items-lg-col-8, .section-merfTop .row-flex .items-lg-col-3{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;    
    }
    .innerBanner-wp {
        padding-top: 80px;
    } 
    .innerBanner-wp .banner-content {
        padding-top: 0px;
    }
    
    .innerBanner-wp .banner-content h1 {
        color: var(--white-color);
        width: 100%;
    }
    .innerBanner-wp .img-wp {
        justify-content: center;
    }

    .innerBanner-wp .position-carIcon {
        right: 0px;
        bottom: -90px;
        width: 100%;
        text-align: center;
    }
   
}

@media (max-width: 768px){
    .innerBanner-wp {
        padding: 70px 0px 0px 0px;
    }    
}

@media (max-width: 576px) {

    .innerBanner-wp .banner-content p{
        font-size: 20px;
    }

    .innerBanner-wp button:not(:first-child) {
        margin-left: 0px;
        margin-top: 30px;
    }
    
    .innerBanner-wp .btnFlex {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .innerBanner-wp .btn-rounded-white {
        margin-right: 0px;
    }
    .innerBanner-wp .position-carIcon img {
        width: 100%;
        height: 100%;
    }
    .innerBanner-wp {
        padding: 70px 0px;
    } 
    .innerBanner-wp .img-wp {
        padding: 0px 30px;
    }
    
    .innerBanner-wp .img-wp img {
        width: 100%;
        height: 100%;
    }
    .section-merfTop .innerBanner-wp .items-lg-col-8{
        margin-bottom: 0;
    }
    .section-merfTop .innerBanner-wp .items-lg-col-3{
        display: none;
    }
    .section-merfTop .innerBanner-wp .position-carIcon {
        display: none;
    }
}

@media (max-width: 450px) {
    .innerBanner-wp .banner-content ul {
        flex-wrap: wrap;
    }

    .innerBanner-wp .banner-content ul li {
        margin-bottom: 10px;
    }

}

/* ----------------------- */

.section-details {
    background-color: var(--white-color);
    padding: 5%;
}

@media (max-width: 992px){
    .section-details {
        padding: 85px 5%;
    }    
}

@media (max-width: 768px){
    .section-details {
        padding: 70px 0;
    }    
}

@media (max-width: 576px){
    .section-details {
        padding: 70px 0px;
    }    
}

.card-details {
    background-color: rgba(51, 153, 255, 0.36);
    border-radius: 15px;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
}

.grayBg {
    background-color: rgba(0, 0, 0, 0.08);
}

.card-details .items {
    width: 25%;
    border-left: solid 6px var(--navy-blue-dark);
    padding: 15px 15px 15px 12px;
    padding-right: 50px;
    flex: 1 1 25%;
    display: flex;
    align-items: center;
}

.card-details p {
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 0;
}

@media (max-width:1150px){
    .card-details .items {
        flex: 1 1 50%;
    }
    .card-details .items:first-child,
    .card-details .items:nth-child(2) {
        margin-bottom: 25px;
    }
    .card-details .items {
        padding-right: 25px;
    }
}

@media (max-width:576px) {
    .card-details {
        padding: 25px;
    }
    .card-details .items {
        flex: 1 1 100%;
        padding-right: 15px;
        margin-bottom: 25px;
    }

    .card-details .items:last-child {
        margin-bottom: 0px;
    }
}

@media (max-width:360px){
    .card-details p {
        font-size: 18px;
    }
}




/* ----------------------- */

.sectionDashboard {
    background-color: var(--navy-blue-dark);
    padding: 12% 5%;
    margin: -5% 0px;
}

.merfxGridBg{
    background: var(--navy-blue-dark) url("../images/merf-grid-bg.png") no-repeat center right;
    background-size: auto 100%;
}

.sectionDashboard h2, .sectionDashboard p {
    color: var(--white-color);
}

@media (max-width: 768px){
    .sectionDashboard {
        padding: 100px 0px;
        margin: -70px 0px;
    }

}

/* ----------------------- */

.sectionDocumentManagement {
    background-color: var(--navy-blue-dark);
    padding: 15% 5% 10% 5%;
    margin-top: -5%;
}

.sectionDocumentManagement h2 {
    color: var(--white-color);
}

.sectionDocumentManagement p {
    margin-bottom: 60px;
}

@media (max-width: 768px){
    .sectionDocumentManagement {
        padding: 70px 0px;
        margin-top: -70px;
    }
}


/* ----------------------- */

/*----SantriDoc css beginning here---- */

.section-santriDoc-banner {
    background-color: var(--blue-color);
}

.section-santriDoc-banner .innerSpace {
    background-image: url(../images/customer-handling-banner.webp), url(../images/customer-handling-banner.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 75px 5%;
    min-height: 710px;
}
@media (max-width:768px){
    .section-santriDoc-banner .innerSpace {
        padding: 70px 0px;
        min-height: auto;
    }
}

.section-santriDoc-banner .productxBg {
    background-image: url(../images/productx-banner.webp), url(../images/productx-banner.png);
    display: flex;
    align-items: center;
}



.section-santriDoc-banner .itemTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
}

.section-santriDoc-banner .itemTitle h1 {
    color: var(--white-color);
    text-shadow: 1px 3px 5px #000;
}

.section-santriDoc-banner .itemTitle p {
    font-size: 26px;
    font-weight: 500;
    color: var(--white-color);
    margin-bottom: 30px;
}

.section-santriDoc-banner .btn-rounded-navyBlue{
    border: solid 2px var(--white-color);
}

.section-santriDoc-banner .btn-flex {
    display: flex;
}

.section-santriDoc-banner .btn-flex button:not(:last-child) {
    margin-right: 20px;
}

.section-santriDoc-banner .itemImg {
    display: flex;
    justify-content: flex-end;
    order: 2;
}

@media (max-width:992px) {
    .section-santriDoc-banner .itemTitle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        order: 2;
    }

    .section-santriDoc-banner .itemImg {
        justify-content: center;
        order: 1;
    }
}

@media (max-width:768px) {
    .section-santriDoc-banner .forMortgageOnly {
        background-image: url(../images/mortgage-solution-mbl-img.webp), url(../images/mortgage-solution-mbl-img.png);
        padding: 75px 0px;
    }
}

@media (max-width:576px) {

    .section-santriDoc-banner .itemTitle{
        margin-bottom: 0;
    }
    
    .section-santriDoc-banner .btn-flex {
        display: unset;
    }

    .section-santriDoc-banner .btn-flex button{
        width: 100% !important;
    }

    .section-santriDoc-banner .btn-flex button:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .section-santriDoc-banner .itemImg {
        margin-bottom: 30px;
        display: none;
    }

    .section-santriDoc-banner .img-wp{
        padding: 0px 20px;
    }

    .section-santriDoc-banner .itemImg img {
        width: 100%;
        height: 100%;
    }
}


/* ----------------------- */


.sectionGradient {
    background: #C2D3E1;
    background: -webkit-linear-gradient(to right, #ffffff, #C2D3E1);
    background: -o-linear-gradient(to right, #ffffff, #C2D3E1);
    background: linear-gradient(to right, #ffffff, #C2D3E1);
    padding: 10% 5%;
  }

  .secPaddingTop{
    padding-top: 15%;
  }

  .imgOrder .imgForMobile{
    display: none;
  }

  .imgOrder .imgWrapper {
    text-align: right;
}

  @media (max-width:1460px) {
      .imgOrder .largeImg {
          height: 100%;
          display: flex;
          align-items: center;
      }
      .imgOrder .largeImg img {
          width: 100%;
          height: auto;
      }

  }
  @media (max-width:992px){
    .imgOrder .imgForMobile{
        display: block;
        text-align: center;
        margin-bottom: 40px;
      }
      .imgOrder .imgWrapper{
        display: none;
      }

  }

  @media (max-width: 768px) {
    .sectionGradient {
        padding: 80px 0px;
      }
  }

  @media (min-width:576.5px){
    .imgOrder .imgForMobile img{
        width: 500px;
        height: auto;
      }
  }

  @media (max-width:576px){
    .imgOrder .imgForMobile img{
        width: 100%;
        height: auto;
      }
    .imgOrder .imgForMobile{
        padding: 0 5%;
      }
  }


/* ----------------------- */

 .createTemplatesSection {
    background-color: var(--gray-light);
    padding: 10% 5%;
  }

  @media (max-width: 768px) {
    .createTemplatesSection {
        padding: 80px 0px;
      }
  }

/* ----------------------- */

  .bannerSectionAbout {
    background-image: url(../images/about-us-bg.webp), url(../images/about-us-bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 15% 5% 20px 5%;
}

.bannerSectionAbout .container-main > div{
    display: flex;
    justify-content: space-between;
}

@media (min-width:768.5px){
    .bannerSectionAbout .container-main > div{
        align-items: center;
    }
}

@media (max-width:768px){
    .bannerSectionAbout {
        padding: 30% 0px 20px 0px;
    }

    .bannerSectionAbout .container-main > div{
        flex-direction: column;
    }
    .bannerSectionAbout .container-main > div h1{
        margin-bottom: 30px;
    }
}

@media (max-width:576px){
    .bannerSectionAbout {
        padding: 36% 0px 20px 0px;
    }
}

.bannerSectionAbout button{
    width: 230px;
}

.aboutUsSectionInner{
    padding: 8% 5%;
}

.aboutUsSectionInner .items-col-8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUsSectionInner .items-col-8 p {
    margin-bottom: 0;
}

/* ************ */


.aboutImgOrder .forMobileOnly {
    display: none;
}

.aboutImgOrder .forWebOnly {
    text-align: right;
}

.aboutImgOrder .itemLeft {
    text-align: left;
}

@media (max-width:1460px) {
      .aboutImgOrder .forWebOnly {
          height: 100%;
          display: flex;
          align-items: center;
      }
      .aboutImgOrder .forWebOnly img {
          width: 100%;
          height: auto;
      }

  }

 @media (max-width:768px){
    .aboutUsSectionInner{
        padding: 70px 0px;
    }
    .aboutImgOrder .forMobileOnly{
        display: block;
        text-align: center;
        margin-bottom: 20px;
      }
      .aboutImgOrder .forWebOnly{
        display: none;
      }
  }

  @media (max-width:450px){
    .aboutImgOrder .forMobileOnly img{
        width: 100%;
        height: auto;
      }
    .aboutImgOrder .forMobileOnly{
        padding: 0 5%;
      }
  }

/* ----------------------- */

/* --- Home page contact form css beginning here ----- */

.sectionContactForm {
    padding: 5% 5%;
    margin: 0;
    background-color: var(--navy-blue-dark);
  }

 .contactText{
    display: flex;
    align-items: center;
}

.contactText>div{
    padding-right: 170px;
}

@media (max-width:1280px) {
    .contactText>div{
        padding-right: 95px;
    }
}

@media (max-width: 992px) {
    .contactText>div{
        padding-right: 50px;
    }
}

.contactText h2 span{
    color: var(--yellow-color2);
}
.contactText h2{
    font-size: 48px;
    color: var(--white-color);
}
.contactText h2, .contactText p{
    color: var(--white-color);
}

.contact-form p {
    color: var(--white-color);
}

.contact-form p.large{
    font-size: 20px;
    font-weight: 600;
}

@media (max-width:768px) {
    .sectionContactForm {
        padding: 70px 0px;
    }
    .contactText h2{
        font-size: 32px;
    }
}

@media (max-width:380px) {
    .contactText h2{
        font-size: 28px;
    }
}

/* --------- */

.contact-form .form-group {
    margin: 0;
    padding: 0;
    position: relative;
    margin-bottom: 20px;
}

.contact-form .form-group label {
    color: var(--white-color);
}

.contact-form .form-group input,
.contact-form .form-group textarea {
    border: 0;
}

.contact-form .form-group input::placeholder,
.contact-form .form-group textarea::placeholder {
    color: rgba(0 0 0/.6);
}
.contact-form .form-group .error-msg {
    bottom: -20px;
}

.contact-form .form-group button {
    width: 100%;
    border-radius: 30px;
}
/* --- Home page contact form css end here ----- */

/* *************************** */

.error-page-container {
    padding: 110px 10px 90px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 690px;
}

.error-page-container>div {
    width: 100%;
}

.error-page-container h1 {
    font-size: 100px;
    color: #313063;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 12px;
}

.error-page-container h1 span {
    font-size: 28px;
    display: block;
    font-weight: 600;
}

.error-page-container h2 {
    font-size: 24px;
    color: var(--secondary-color);
    font-weight: 400;
}

.error-page-container h1::after, .error-page-container h2::after {
    content: "";  
    width: 0px;  
    height: 0px;    
  }

.error-page-container p {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 30px;
}

.error-page-container .btn-primary {
    width: 264px;
}

@media (max-width: 576px) {
    .error-page-container {
        padding: 60px 10px;
        height: 100%;
    }

    .error-page-container .btn-blue {
        width: 100%;
    }
}

/* ******************** */


/* The custom Checkbox css beginning here*/
.customCheckbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #2b2b2b;
    font-weight: 400;
    line-height: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 35px;
    margin-bottom: 10px;
    padding-top: 3px;
  }
  
  .customCheckbox .text-small {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
  
  /* Hide the browser's default checkbox */
  .customCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .customCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border: solid 2px #2b2b2b;
    border-radius: 5px;
  }
  
  /* On mouse-over, add a grey background color */
  .customCheckbox:hover input~.customCheckmark {
    background-color: #fff;
    border: solid 2px #2b2b2b;
  }
  
  /* When the checkbox is checked, add a blue background */
  .customCheckbox input:checked~.customCheckmark {
    background-color: #004fc7;
    border: solid 2px #004fc7;
    border-radius: 5px;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .customCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .customCheckbox input:checked~.customCheckmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .customCheckbox .customCheckmark:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .customCheckbox input:focus~.customCheckmark {
    outline: solid 2px #5d9dd5;
    box-shadow: 0 0px 8px #5e9ed6;
  }
  
  /* The custom Checkbox css end here*/
  

  
  
  .floatingLabel {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  .floatingLabel input {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    height: 48px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    border: 0 !important;
    padding: 11px 12px !important;
    border-radius: 5px;
  }
  
  .floatingLabel input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .floatingLabel select {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    height: 48px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    border: 0 !important;
    padding: 11px 30px 11px 12px !important;
    border-radius: 5px;
  }
  
  .floatingLabel select:focus {
    outline: none;
  }
  
  .floatingLabel textarea {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    height: 90px;
    border: 0 !important;
    padding: 11px 12px !important;
    border-radius: 5px;
  }
  
  .floatingLabel textarea:focus {
    outline: none;
  }
  
  .floatingLabel label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .floatingLabel select:focus~label,
  .floatingLabel select:valid~label,
  .floatingLabel textarea:focus~label,
  .floatingLabel textarea:valid~label,
  .floatingLabel input:focus~label,
  .floatingLabel input:valid~label {
    top: -20px;
    left: 5px;
    font-size: 14px;
    color: #004fc7;
  }  
  
  /* *********************** */


  /* *** --Quick chat css Beginning here----*** */

.slide-quick-chat {
    position: fixed;
    top: 0%;
    left: 0%;
    transform: translate(100%, 0%);
    z-index: 22;
    width: 100%;
    height: 100%;
    transition: all linear 200ms;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
  }
  
  .slide-quick-chat .container-chat {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    min-height: 250px;
    max-height: 90%;
    width: 350px;
    display: flex;
    flex-direction: column;
    border-radius: 5px 0px 0px 5px;
    padding: 10px 15px 15px 15px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  @media (max-width: 380px) {
    .slide-quick-chat{
      padding-left: 15px;
    }
    .slide-quick-chat .container-chat {
      width: 100%;
    }
  }
  
  .show-chat {
    transform: translate(-0%, 0%);
  }
  
  .slide-quick-chat .container-chat .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: solid 1px rgb(0 0 0/.1);
    padding-bottom: 6px; */
    margin-bottom: 20px;
  }
  
  .slide-quick-chat .container-chat h2{
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .slide-quick-chat .container-chat a.close {
    margin-top: -3px;
    margin-right: -2px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
  
  .slide-quick-chat .container-chat a.close:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
  }
  
  .container-chat .floatingLabel label {
    font-size: 15px;
    top: 10px;
  }
  .container-chat .floatingLabel input{
    font-size: 15px;
    height: 44px;
  }
  .container-chat  .floatingLabel textarea {
    font-size: 15px;
    height: auto;
    padding: 8px 12px !important;
  }
  .errorMsg {
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
  
  .container-chat .checkboxflex {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .container-chat .checkboxflex .itemContent label{
    padding-right: 4px;
  }
  .container-chat .checkboxflex .itemContent label,.container-chat .checkboxflex .itemContent p {
    display: inline;
    font-size: 13px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .container-chat .checkboxflex .itemContent p a{
    font-size: 13px;
    color: var(--blue-color3);
  }
  .container-chat .checkboxflex .itemContent p a:hover{
    text-decoration: underline;
  }

  .container-chat .checkboxflex .inFlex{
    line-height: normal;
  }
  
  .container-chat .btn-primary{
    height: 48px;
    font-size: 16px;
  }
  /* *** --quick chat css end here----*** */




  /* ---New card beginning here---- */

.sectionSecondaryCard {
    padding: 5% 0px;
    margin: 0;
    /* border-bottom: solid 1px rgba(0, 0, 0, .10); */
}

.sectionSecondaryCard .cardInner {    
    padding: 160px 5% 100px 5%;
    background-color: var(--gray-light);
}

@media (min-width: 768.5px){
    .sectionSecondaryCard .cardInner .titleFixed {
        position: absolute;
        left: 10px;
        top: -60px;
    }
}

.sectionSecondaryCard .cardInner .customCol70 {
    flex: 0 0 72%;
    max-width: 72%;
    display: flex;
    align-items: center;
    /* padding-top: 60px; */
}

.sectionSecondaryCard .cardInner .customCol30 {
    flex: 0 0 28%;
    max-width: 28%;
}

  .sectionSecondaryCard .cardInner .card-primary{
    padding: 40px 40px 70px 40px;
    min-height: unset;
    text-align: left;
    align-items: flex-start;
  }
  .sectionSecondaryCard .cardInner .card-primary h3,
  .sectionSecondaryCard .cardInner .card-primary p{
    text-align: left;
  }

  .sectionSecondaryCard .itemInner .card-primary{
    justify-content: flex-start;
    height: 100%;
  }

  
  .sectionSecondaryCard .cardInner .customCol30 .card-primary {
      padding-top: 50px;
      padding-bottom: 70px;
  }

  .sectionSecondaryCard .cardInner .customCol30 .card-primary .readMore a {
      color: var(--white-color);
  }

  .sectionSecondaryCard .cardInner .customCol30 .card-primary .readMore a:hover {
      background: rgba(255 255 255/0.08)
  }

@media (max-width: 1100px){
    .sectionSecondaryCard .cardInner .customCol70 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }

    .sectionSecondaryCard .cardInner .customCol30 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media (max-width: 992px){
    .sectionSecondaryCard .cardInner .card-primary{
        padding: 40px 15px 70px 15px;
      }

}

@media (max-width: 768px){
    .sectionSecondaryCard .cardInner {    
        padding: 70px 0;
    }
    .sectionSecondaryCard .cardInner .customCol70,
    .sectionSecondaryCard .cardInner .customCol30 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .sectionSecondaryCard .cardInner .titleFixed h2{
        margin-bottom: 35px;
    }

    .forCardBg1{
        background-color: var(--cardBg-color);
    }
    .forCardBg2{
        background-color: var(--white-color);
    }
    .sectionSecondaryCard .cardInner .customCol70 .row-flex .items-col-2:last-child{
        margin-bottom: 0;
    }
}


/* *********************** */

.underwritingBg {
    background: var(--navy-blue-dark) url("../images/underwriting-setion-bg.webp") no-repeat center right;
    background-size: auto 100%;
}

.lineageBg {
    background: #0E0133 url("../images/lineage-hub.webp") no-repeat center right;
    background-size: auto 100%;
}

.transformBg {
    background: #1A1A53 url("../images/transformBg.webp") no-repeat center right;
    background-size: auto 100%;
}

@media (max-width: 992px){
    .underwritingBg {
        background: var(--navy-blue-dark) url("../images/underwriting-setion-bg.webp") no-repeat center left;
        background-size: 100% 100%;
    }
    .lineageBg {
        background: #0E0133 url("../images/lineage-hub.webp") no-repeat center left;
        background-size: 100% 100%;
    }
    .transformBg {
        background: #1A1A53 url("../images/transformBg.webp") no-repeat center left;
        background-size: 100% 100%;
    }
}
@media (max-width: 768px){
    .underwritingBg {
        background: var(--navy-blue-dark);
        background-size: 100% 100%;
    } 
    .lineageBg {
        background: var(--navy-blue-dark);
        background-size: 100% 100%;
    } 
    .transformBg {
        background: var(--navy-blue-dark);
        background-size: 100% 100%;
    }  
}
 

/* *********************************************** */
/* ---Common sub page css beginning here--- */


.section-topInner {
    background-color: var(--navy-blue-dark);
    width: 100%;
    padding: 5%;
}
.content-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-topInner h1{
    color: var(--white-color);
    margin-bottom: 0;
}
.content-flex .itemTitle{
    width: 50%;
}

@media (max-width: 991.5px){
    .content-flex .itemTitle{
        width: 60%;
    }
    .section-topInner h1{
        font-size: 40px;
    }
}

@media (max-width: 768px){
    .section-topInner{
        padding: 60px 0px;
    }
    .content-flex{
        flex-wrap: wrap;
    }
    .content-flex .itemTitle{
        width: 100%;
        margin-bottom: 35px;
    }    
}

@media (max-width: 576px){
    .content-flex > div{
        width: 100%;
    }
}


.section-content-flex{
    display: flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 50px;
}

.section-content-flex .itemsOne{
    width: 220px;
    padding-right: 10px;
    padding-left: 10px;
    order: 1;
}
.section-content-flex .itemsTwo{
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    order: 2;
}
.section-content-flex .itemsTwo p{
    font-size: 24px;
}
.section-content-flex .itemsThree{
    width: 280px;
    padding-right: 10px;
    padding-left: 10px;
    order: 3;
}

.section-content-flex .img-wp img{
    width: 100%;
    height: auto;
}


@media (max-width: 575.5px){
    .section-content-flex .itemsTwo{
        flex: unset;
        width: 100%;
        order: 3;
    }
    .section-content-flex .itemsThree{
        width: 100%;
        order: 2;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 380px){
    .img-wp{
        width: 100%;
    }
}


@media (max-width: 992px){
    .section-content-flex .itemsOne{
        width: 140px;
    }
}

@media (max-width: 768px){
    .section-content-flex{
        margin-bottom: 30px;
    }
    .section-content-flex .itemsOne{
        width: 100%;
        margin-bottom: 20px;
    }
}





/* ----------------------- */
.share-wp p{
    font-weight: 600;
}

.share-icons a {
    font-size: 14px;
    color: var(--secondary-color);
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    align-items: center;
    cursor: pointer;    
}

@media (min-width: 768.5px){
    .share-icons a:not(:last-child) {
        margin-bottom: 20px;
    }
}
.share-icons a span {
    margin-left: 6px;
}
.share-icons a:hover {
    opacity: 0.8;
    text-decoration: underline;
}

@media (max-width: 768px){
    .share-icons{
        display: flex;
        align-items: center;
    }
    .share-icons a:not(:last-child) {
        margin-right: 20px;
    }
}

@media (max-width: 360px){
    .share-icons a:not(:last-child) {
        margin-right: 10px;
    }
}

/* ----------------------- */

.section-icons{
    padding: 8% 0;
}

.innerIcon{
    display: flex;
    align-items: center;
}
.innerIcon img {
    margin-right: 20px;
}
.innerIcon p {
    font-size: 20px;
    color: var(--blue-color4);
    font-weight: 600;
    margin-bottom: 0;
    width: 80%;
}

@media (max-width: 450px){
    .innerIcon img {
        margin-right: 15px;
        width: 75px;
        height: auto;
    }
    .innerIcon p {
        font-size: 16px;
        width: 100%;
    }
}


@media (min-width: 768.5px){
    .onlyForIcons > div:first-child, .onlyForIcons > div:nth-child(2){
        margin-bottom: 50px;
    }
}

@media (max-width: 768px){
    .onlyForIcons > div:not(:last-child){
        margin-bottom: 25px;
    }
    .section-icons{
        padding: 60px 0 30px 0;
    }
}


.section-features-wp{
    padding: 5% 0;
}

.section-features-wp h3{
    font-size: 26px;
}



@media (min-width: 1250px) {
    .section-features-wp .row-flex .items-col-2:last-child {
        display: flex;
        justify-content: flex-end;
    }

    .section-features-wp .imgWp {
        width: 85%;
    }
}

.section-features-wp .imgWp img{
    width: 100%;
    height: auto;
}


@media (max-width: 768px){
    .section-features-wp{
        padding: 30px 0 0px 0;
    }
    .section-features-wp .row-flex .items-col-2:last-child {
        display: flex;
        justify-content: center;
    }
    .section-features-wp .imgWp{
        max-width: 500px;
    }
    
}





/* *********************************************** */
/* ---RegZen page css beginning here--- */

.section-santriDoc-banner .regzenBg {
    background-image: url(../images/productxBg.webp), url(../images/productxBg.png);
    display: flex;
    align-items: center;
}

.regzonTopDetails {
    display: flex;
}
.regzonTopDetails > div{
    width: 33.33%;
    display: flex;
    align-items: center;
}

.section-details-Top {
    background-color: var(--white-color);
    padding: 7% 5%;
  }

.regzonTopDetails img{
    margin-right: 20px;
}
.regzonTopDetails p{
    font-size: 20px;
    color: var(--blue-color4);
    font-weight: 600;
    padding-right: 20px;
    margin-bottom: 0;
    max-width: 230px;
}

@media only screen and (max-width: 992px) and (min-width: 768.5px) {
    .regzonTopDetails img{
        width: 70px;
        height: 70px;
        margin-right: 15px;
    }
    .regzonTopDetails p{
        font-size: 18px;
        padding-right: 15px;
    }  
}

@media (max-width: 768px){
    .section-details-Top {
        background-color: var(--white-color);
        padding: 70px 0px;
      }  
      .regzonTopDetails {
        flex-direction: column;
    }  
    .regzonTopDetails > div{
        width: 100%;
    }
    .regzonTopDetails > div:not(:last-child){
        margin-bottom: 20px;
    }
    .regzonTopDetails p{
        padding-right: 0px;
        max-width: 100%;
    }
  }

  @media (max-width: 480px){
    .regzonTopDetails img{
        width: 70px;
        height: 70px;
        margin-right: 15px;
    }
    .regzonTopDetails p{
        font-size: 18px;
        padding-right: 15px;
    }  
}

/* ******************** */

.sectionPrimaryCardRegzen {
    background-color: var(--gray-light);
    padding: 5%;
    margin: 0;
    /* border-bottom: solid 1px rgba(0, 0, 0, .10); */
  }

.regZenCards .card-primary {
    height: 100%;
    padding-bottom: 45px;
}

.regZenCards .card-primary img{
    margin-bottom: 25px;
}
.regZenCards .card-primary h3{
    font-size: 20px;
}

@media only screen and (max-width: 992px) and (min-width: 768.5px) {
    .regZenCards .items-col-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
}
@media (max-width: 768px){
    .sectionPrimaryCardRegzen {
        background-color: var(--gray-light);
        padding: 70px 0px;
        margin: 0;
      }
}



/* -------------- */

.sectionGenAi {
    /* background-color: var(--navy-blue-dark); */
    background: var(--navy-blue-dark) url("../images/genAiBg.png") no-repeat center right;
    background-size: auto 100%;
    padding: 12% 5%;
    margin: 0px;
  }
  .sectionGenAi h2{
    font-size: 32px;
    font-weight: 400;
  }
  .sectionGenAi h2, .sectionGenAi p{
    color: var(--white-color);
  }

  @media only screen and (max-width: 1366px) and (min-width: 992.5px){
    .sectionGenAi .row-flex .items-lg-col-5 {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
      }
  }

  @media (max-width: 768px){
    .sectionGenAi {
        background: var(--navy-blue-dark) url("../images/genAiBg.png") no-repeat center center;
        background-size: auto 100%;
        padding: 70px 0px;
      }
  }

  /* -------------- */

.sectionCombine {
    background-color: var(--gray-light);
    padding: 10% 5%;
    margin: 0;
}

@media (max-width: 768px){
    .sectionCombine {
        padding: 70px 0px;
      }
  }


  @media only screen and (max-width: 1365px) and (min-width: 992.5px) {      
    .sectionCombine .row-flex .items-lg-col-2 {
          -ms-flex: 0 0 70%;
          flex: 0 0 70%;
          max-width: 70%;
      }
      .sectionCombine .row-flex .items-lg-col-8 {
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        max-width: 80%;
      }
  }
  
.combineFlex{
    display: flex;
}
.combineFlex .leftItems{
    width: 76%;
    border-right: dashed 3px rgb(0 0 0/.08);
    padding-right: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0px;
}
.combineFlex .leftItems > div{
    width: 50%;
    display: flex;
    align-items: center;
}
.combineFlex .leftItems img{
    margin-right: 20px;
}
.combineFlex .leftItems p{
    padding-right: 20px;
    margin-bottom: 0px;
}
.combineFlex .rightItem{
    position: relative;
    width: 24%;
    padding-left: 40px;
    text-align: center;
}
.combineFlex .rightItem > img{
    position: absolute;
    left: -28px;
    top: 50%;
    margin-top: -28px;
}
.combineFlex .rightItem > div img{
    margin-bottom: 20px;
}

@media (max-width: 992px){
    .combineFlex{
        flex-direction: column;
    }
    .combineFlex .leftItems{
        width: 100%;
        border-right: dashed 0px rgb(0 0 0/.08);
        border-bottom: dashed 3px rgb(0 0 0/.08);
        padding-right: 0px;
        padding-bottom: 50px;
    }
    .combineFlex .rightItem{
        width: 100%;
        padding-left: 0px;
        padding-top: 50px;
    }
    .combineFlex .rightItem > img{
        left: 50%;
        margin-top: -30px;
        top: 0%;
        margin-left: -28px;
        rotate: 90deg;
    }
}

@media (max-width: 768px){
    .combineFlex .leftItems {
        flex-direction: column;
      }
      .combineFlex .leftItems > div {
        width: 100%;
      }
}

@media (max-width: 380px){
    .combineFlex .leftItems img{
        width: 70px;
        height: 70px;
    }
    .combineFlex .leftItems p {
        padding-right: 15px;
        font-size: 15px;
      }
      .combineFlex .rightItem > div img {
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
      }
      .combineFlex .rightItem p{
        font-size: 15px;
      }
}

/* ---------------- */


.sectionRegZenOffers {
    background: var(--navy-blue-dark) url("../images/regZenOffersBg.png") no-repeat center right;
    background-size: auto 100%;
    padding: 10% 5%;
    margin: 0;
  }

  @media (max-width: 1100px){
    .sectionRegZenOffers {
        background: var(--navy-blue-dark) url("../images/regZenOffersBg.png") no-repeat center center;
        background-size: auto 100%;
        padding: 10% 5%;
      }
  }


  .sectionRegZenOffers h2, .sectionRegZenOffers p{
    color: var(--white-color);
  }


  .sectionRegZenOffers .offer-cards{
    display: flex;
    align-items: center;
  }
  .sectionRegZenOffers .offer-cards p{
    font-size: 20px;
    font-weight: 600;
    padding-left: 20px;
    max-width: 415px;
    margin-bottom: 0;
  }

  @media (max-width: 768px){
    .sectionRegZenOffers .offer-cards p{
        font-size: 18px;
        font-weight: 600;
        padding-left: 20px;
        max-width: 100%;
      }
      .sectionRegZenOffers .offer-cards img{
        width: 70px;
        height: 70px;
      }
  }


  /* ------- */

.sectionGlobal{
    background: var(--white-color);
    padding: 5%;
    margin: 0;    
}
@media (max-width: 768px){
    .sectionGlobal{
        padding: 70px 0px;   
    }
}

.sectionGlobal .globalFlex{
    display: flex;
    justify-content: space-between;
}
.sectionGlobal .globalFlex > div{
    width: 22%;
    text-align: center;
}
.sectionGlobal .globalFlex img{
    margin-bottom: 20px;
}

@media(max-width: 991.5px){
    .sectionGlobal .globalFlex {
        flex-wrap: wrap;
      }
    .sectionGlobal .globalFlex > div {
        width: 46%;
      }
}
@media only screen and (max-width: 991.5px) and (min-width: 576.5px){
    .sectionGlobal .globalFlex > div:first-child, .sectionGlobal .globalFlex > div:nth-child(2){
        margin-bottom: 20px;
    }
}
@media(max-width: 576px){
    .sectionGlobal .globalFlex > div {
        width: 100%;
      }
      .sectionGlobal .globalFlex > div:not(:last-child){
        margin-bottom: 20px;
      }
}


/* ---RegZen page css end here--- */

/* *********************************************** */


/* *********************************************** */

/* ---RegZen page Design Second css end here--- */

.cardRegZen {
    background-color: rgb(35 80 186/0.10);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 15px;
    padding: 40px;
}

.cardRegZen>div {
    border-left: solid 6px var(--navy-blue-dark);
    display: flex;
    align-items: center;
    padding: 4px 14px;
    padding-right: 40px;
}

.cardRegZen p {
    font-size: 20px;
    color: var(--navy-blue-dark);
    font-weight: 400;
    margin-bottom: 0;
}

@media (max-width:1150px) {
    .cardRegZen>div {
        flex: 1 1 50%;
    }

    .cardRegZen>div:first-child,
    .cardRegZen>div:nth-child(2) {
        margin-bottom: 25px;
    }

    .cardRegZen>div {
        padding-right: 25px;
    }
}

@media (max-width:576px) {
    .cardRegZen {
        padding: 25px;
    }

    .cardRegZen>div {
        flex: 1 1 100%;
        padding-right: 15px;
        margin-bottom: 25px;
    }

    .cardRegZen>div:last-child {
        margin-bottom: 0px;
    }
}

@media (max-width:360px) {
    .cardRegZen p {
        font-size: 18px;
    }
}

/* ------------------ */


.sectionCombineDTwo {
    background-color: var(--navy-blue-dark);
    padding: 5%;
}

@media (max-width:768px) {
    .sectionCombineDTwo {
        padding: 70px 0px;
    }
}

.sectionCombineDTwo h2,
.sectionCombineDTwo p,
.sectionCombineDTwo ul li {
    color: var(--white-color);
}

.bulletPoints-wp {
    margin-left: 20px;
    margin-bottom: 25px;
}

.bulletPoints-wp>div {
    position: relative;
    margin-bottom: 20px;
}

.bulletPoints-wp>div::before {
    content: "";
    width: 2px;
    height: 80%;
    background-color: var(--white-color);
    position: absolute;
    top: 14px;
    left: 6px;
}

.bulletPoints-wp>div::after {
    content: "";
    width: 12px;
    height: 12px;
    background: url("../images/icons/arrow-small-icon.svg");
    position: absolute;
    bottom: -16px;
    left: 0px;
}


.bulletPoints-wp p {
    position: relative;
    font-size: 14px;
    color: var(--white-color);
    margin-bottom: 5px;
    padding-left: 25px;
}

.bulletPoints-wp .circle::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 30px;
    background-color: var(--white-color);
    position: absolute;
    top: 6px;
    left: 0;
}

.sectionCombineDTwo .items-lg-col-5 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* -------- */

.sectionFeatures {
    background-color: var(--gray-light);
    padding: 5%;
}


.sectionFeatures .card-primary {
    height: 100%;
}

.sectionLineageHub {
    background-color: var(--navy-blue-dark);
    padding: 5%;
}

.sectionLineageHub h2 {
    color: var(--white-color);
}

/* .---section-santriDoc-banner .regzenBg {
    background-image: url(../images/productxBg.webp), url(../images/productxBg.png);
    display: flex;
    align-items: center;
} */

.lineageHubBg {
    background: #0E0133 url("../images/lineage-hub-img.webp") no-repeat center right;
    background-size: auto 100%;
}

@media (max-width: 768px) {
    .lineageHubBg {
        background: #0E0133 url("../images/lineage-hub-img.webp") no-repeat center center;
        background-size: auto 100%;
    }
}

@media (max-width: 768px) {
    .sectionFeatures {
        padding: 70px 0px;
    }

    .sectionLineageHub {
        padding: 70px 0px;
    }
}





/* ----------------------- */
/* ----------------------- */
/* ----------------------- */









/* 


.itemOrder {
    display: flex;
}

.itemOrder .orderOne {
    order: 1;
}

.itemOrder .orderTwo {
    order: 2;
}

@media (max-width:992px){
  .itemOrder .orderOne {
      order: 2;
  }

  .itemOrder .orderTwo {
      order: 1;
  }
} */

.relative{
    position: relative;
}


.textVCenter{
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.items-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.itemCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}


.pb-75 {
    padding-bottom: 75px;
}

.pb-40 {
    padding-bottom: 40px;
}

.ml--16 {
    margin-left: -16px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-15 {
    padding-top: 15px;
}

.pt-10 {
    padding-top: 10px;
}

.ml-5 {
    margin-left: 5px;
}

.pb-0 {
    padding-bottom: 0px;
}

.mb-10p{
    margin-bottom: 10%;
}


.mb-100 {
    margin-bottom: 100px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}


/* @media only screen and (max-width: 1280px) and (min-width: 992px) {
    .header-nav nav ul li {
        padding: 0 10px;
    }   
} */